// src/pages/NewsPage.js
import React from "react";
import "./LeaderboardsPage.css";
function LeaderboardsPage() {
  return (
    <div>
      <h1>Global Leaderboards</h1>
      <div className="alert alert-warning">
        <p>
          Due to Valve disabling Deadlock's API, it's currently not possible to
          retrieve match data. We appreciate your patience.
        </p>
      </div>
      <p>Check back soon for updates on our progress!</p>
    </div>
  );
}

export default LeaderboardsPage;

import React, { useState } from "react";

const CollapsibleSection = ({ title, children, isOpen = false }) => {
  const [isContentOpen, setIsContentOpen] = useState(isOpen);
  const [triangleClass, setTriangleClass] = useState(
    isOpen ? "triangle triangle-up" : "triangle"
  );

  const toggleVisibility = () => {
    setIsContentOpen(!isContentOpen);
    setTriangleClass(
      triangleClass === "triangle" ? "triangle triangle-up" : "triangle"
    );
  };

  return (
    <div>
      <h3 onClick={toggleVisibility} className="collapse-header">
        {title}
        <div className={triangleClass} />
      </h3>
      <div className={`collapsible-content ${isContentOpen ? "" : "hidden"}`}>
        {children}
      </div>
    </div>
  );
};

export default CollapsibleSection;

// ReadOnlySkillBuildComponent.js
import React from "react";
import "./SkillBuildComponent.css";
import Tooltip from "@mui/material/Tooltip";
import AbilityTooltip from "./AbilityTooltip";
import AbilityIcon from "../svg/ability.png";

const ReadOnlySkillBuildComponent = ({
  skillBuildData,
  heroName,
  abilities,
}) => {
  if (!skillBuildData || !abilities) return null; // Add null checks

  const { skillBuild } = skillBuildData;

  const displayLevel = (abilityIndex, levelIndex) => {
    const assignedLevels = skillBuild[abilityIndex].filter(
      (level) => level !== null
    );
    const positionInAssignedLevels = assignedLevels.indexOf(
      skillBuild[abilityIndex][levelIndex]
    );

    if (positionInAssignedLevels === 0) return "";
    if (positionInAssignedLevels === 1) return "1";
    if (positionInAssignedLevels === 2) return "2";
    if (positionInAssignedLevels === 3) return "5";
    return ""; // Return empty string for other positions or unassigned levels
  };

  return (
    <div className="skill-build-section">
      <div className="ability-grid">
        {abilities.map((ability, abilityIndex) => (
          <React.Fragment key={ability.id}>
            <div className="ability-name">
              <Tooltip title={<AbilityTooltip ability={ability} />}>
                <img
                  src={`/heroes/${heroName
                    .toLowerCase()
                    .replace(/\s+/g, "_")}/assets/ability_${ability.name
                    .toLowerCase()
                    .replace(/\s+/g, "_")}.png`}
                  alt={ability.name}
                  className={`ability-icon`}
                />
              </Tooltip>{" "}
            </div>
            {skillBuild[abilityIndex].map((level, levelIndex) => (
              <div key={levelIndex} className="ability-container">
                {level ? (
                  <div className="ability-content">
                    <img src={AbilityIcon} alt={ability.name} />
                    <span className="ability-level">
                      {displayLevel(abilityIndex, levelIndex)}
                    </span>
                  </div>
                ) : (
                  <div className="ability-empty">&nbsp;</div>
                )}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ReadOnlySkillBuildComponent;

import React from "react";
import SearchGuides from "../components/SearchGuides";
import "./HomePage.css";
import TopWinrateHeroes from "../components/TopWinrateHeros";
import LowestWinrateHeroes from "../components/LowestWinrateHeros";
import HeroesData from "../components/HeroesData";
import FeaturedGuides from "../components/FeaturedGuides";
import HighestRatedGuides from "../components/HighestRatedGuides";
import TierList from "../components/TierList";
import HeroesChart from "../components/HeroWinrateChart";

const HomePage = () => {
  const data = HeroesData["Patch 1.2"];

  // Choose the patch you want to display
  const selectedPatch = "Patch 1.2";
  const heroes = HeroesData[selectedPatch];

  return (
    <>
      <div className="homepage-wrapper">
        <div className="home-container-top">
          <h1>Welcome to Deadlock Guides</h1>
          <p>Create guides, track stats, and explore hero analytics.</p>
          <SearchGuides />
        </div>
        <div style={{ marginBottom: "20px" }} className="guides-container">
          <FeaturedGuides /> {/* Add the FeaturedGuides component here */}
          <HighestRatedGuides />
        </div>
        <div className="home-container">
          {" "}
          <h2 style={{ marginTop: "0px", marginBottom: "0px" }}>
            Hero Popularity vs. Win Rate
          </h2>
          <div className="chart-wrapper">
            <HeroesChart data={data} />
          </div>
        </div>
        <div className="hero-winrate-container">
          <TopWinrateHeroes heroes={heroes} />
          <LowestWinrateHeroes heroes={heroes} />
        </div>
      </div>
    </>
  );
};

export default HomePage;

import React from "react";
import heroData from "./heroData";

const getAbilityName = (ability) => ability.name || "Unknown Ability";
const getAbilityDescription = (ability) => ability.description || "";
const getAbilityCooldown = (ability) => ability.cooldown || "0s";
const getAbilityCastRange = (ability) => ability.castRange || "0";
const getAbilityDuration = (ability) => ability.duration || "0s";

const AbilityTooltip = ({ ability }) => (
  <div
    style={{
      fontSize: "15px",
      padding: "8px 12px",
      backgroundColor: "#191937",
      borderRadius: "3px",
      color: "#fff",
    }}
  >
    <h4
      style={{
        color: "#2196f3",
        marginTop: "2px",
        fontSize: "17px",
        marginBottom: "15px",
      }}
    >
      {getAbilityName(ability)}
    </h4>
    <p style={{ marginBottom: "10px" }}>{getAbilityDescription(ability)}</p>
    <ul>
      {getAbilityCooldown(ability) !== "0s" && (
        <li className="tooltip-styling">
          <span>Cooldown:&nbsp;</span>
          <span>{getAbilityCooldown(ability)}</span>
        </li>
      )}
      {getAbilityCastRange(ability) !== "0" && (
        <li className="tooltip-styling">
          <span>Cast Range:&nbsp;</span>
          <span>{getAbilityCastRange(ability)}</span>
        </li>
      )}
      {getAbilityDuration(ability) !== "0s" && (
        <li className="tooltip-styling">
          <span>Duration:&nbsp;</span>
          <span>{getAbilityDuration(ability)}</span>
        </li>
      )}
    </ul>
  </div>
);

export default AbilityTooltip;

import React from "react";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{payload[0].dataKey}</p>
        <p className="value">{payload[0].value.toFixed(2)}</p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;

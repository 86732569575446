import React from "react";
import itemDataWithPaths from "./itemDataWithPaths";
const getItemData = (path) => {
  const flattenedData = Object.values(itemDataWithPaths).flat();
  return flattenedData.find((item) => item.path === path);
};
const getItemName = (path) => {
  const item = getItemData(path);
  return item?.name || "Unknown Item";
};

const getItemCost = (path) => {
  const item = getItemData(path);
  return item?.cost || 0;
};

const getItemBackgroundColor = (path) => {
  const item = getItemData(path);
  return item?.type === "Spirit"
    ? "#dd9cff"
    : item?.type === "Vitality"
    ? "#87c81d"
    : "#fbab4d";
};

const getItemCostBackgroundColor = (path) => {
  const item = getItemData(path);
  return item?.type === "Spirit"
    ? "#8b55b4"
    : item?.type === "Vitality"
    ? "#659819"
    : "#ca7a03";
};
const getItemPurchaseStats = (item) => {
  if (!item) return null; // or a default value

  const itemData = getItemData(item);
  if (!itemData) return null; // or a default value

  return itemData?.purchaseStats ?? null; // or a default value
};
const getItemPassiveComponent = (item) => {
  return getItemData(item)?.passive ?? null; // or a default value
};
const getItemActiveComponent = (item) => {
  if (!item) return null; // or a default value

  const itemData = getItemData(item);
  if (!itemData) return null; // or a default value

  return itemData?.active ?? null; // or a default value
};
const getItemComponent = (item) => {
  if (!item) return null; // or a default component

  const itemData = getItemData(item);
  if (!itemData) return null; // or a default component

  return itemData.componentOf ?? null; // or a default value
};
const ItemTooltip = ({ item }) => (
  <div
    style={{
      fontSize: "15px",
      padding: "8px 12px",
      backgroundColor: "#191937",
      borderRadius: "3px",
      color: "#fff",
    }}
  >
    <h4
      style={{
        color: "#2196f3",
        marginTop: "2px",
        fontSize: "17px",
        marginBottom: "15px",
      }}
    >
      {getItemName(item)}
    </h4>
    {getItemComponent(item) && (
      <div>
        <h4 style={{ marginTop: "15px", marginBottom: "0px" }}>COMPONENT:</h4>
        <span>{getItemComponent(item)}</span>
      </div>
    )}
    <ul>
      {(getItemPurchaseStats(item) || []).map((stat) => (
        <li className="tooltip-styling" key={stat.name}>
          <span>{stat.value}&nbsp;</span>
          <span style={{ paddingBottom: "0px" }}>{stat.name}</span>
        </li>
      ))}
    </ul>
    {getItemActiveComponent(item) && (
      <div>
        <h4 style={{ marginBottom: "0px" }}>
          ACTIVE: {getItemActiveComponent(item).cooldown}s Cooldown
        </h4>
        <ul className="ul-tooltip-styling">
          {getItemActiveComponent(item || []).description}
          {getItemActiveComponent(item || []).notes}
          {getItemActiveComponent(item || []).effects?.map((effect) => (
            <li className="tooltip-styling" key={effect.name}>
              <span style={{ paddingBottom: "0px" }}>{effect.value}&nbsp;</span>
              <span>{effect.name}</span>
            </li>
          ))}
        </ul>
        {getItemActiveComponent(item).duration && (
          <span>Duration: {getItemActiveComponent(item).duration}s</span>
        )}
      </div>
    )}
    {getItemPassiveComponent(item) && (
      <div>
        <h4 style={{ marginBottom: "0px" }}>PASSIVE</h4>
        <ul className="ul-tooltip-styling">
          {getItemPassiveComponent(item).description}
          {(getItemPassiveComponent(item).effects || []).map((effect) => (
            <li className="tooltip-styling" key={effect.name}>
              <span style={{ paddingBottom: "0px" }}>{effect.value}&nbsp;</span>
              <span>{effect.name}</span>
            </li>
          ))}
          {getItemPassiveComponent(item).condition && (
            <li className="tooltip-styling">
              <span>Condition:&nbsp;</span>
              <span>{getItemPassiveComponent(item).condition}</span>
            </li>
          )}
        </ul>
      </div>
    )}
    <p
      style={{
        marginTop: 10,
        fontWeight: "bold",
        marginBottom: "3px",
        marginTop: "15px",
        color: "#98ffde",
      }}
    >
      <img
        src={`/20px-Souls_icon.png`}
        alt="Souls"
        style={{ height: 14, color: "#98ffde" }}
      />
      &nbsp;
      {getItemCost(item)}
    </p>
  </div>
);

export default ItemTooltip;

// GuidesContext.js
import React, { createContext, useContext, useState } from "react";

const GuidesContext = createContext();

export const GuidesProvider = ({ children }) => {
  const [guides, setGuides] = useState([]);

  const addGuide = (guide) => {
    setGuides((prevGuides) => [...prevGuides, guide]);
  };

  return (
    <GuidesContext.Provider value={{ guides, addGuide }}>
      {children}
    </GuidesContext.Provider>
  );
};

export const useGuides = () => useContext(GuidesContext);

import React, { useState } from "react";
import HeroTable from "../components/HeroTable";
import "./HeroesPage.css"; // Import the CSS for styling

import HeroesData from "../components/HeroesData";
const heroes = HeroesData;

function HeroesPage() {
  const [selectedPatch, setSelectedPatch] = useState("Patch 1.2");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectPatch = (patch) => {
    setSelectedPatch(patch);
    setIsDropdownOpen(false);
  };

  return (
    <div className="heroes-page">
      <h1 style={{ fontSize: "36px", marginBottom: "0px" }}>Hero Statistics</h1>
      <h2
        style={{
          fontSize: "18px",
          color: "#cddcfe",
          marginTop: "20px",
          marginBottom: "40px",
        }}
      >
        All of the heroes in Deadlock. Up to date stats, builds, abilities,
        win-rates, and pick-rates.*
      </h2>
      <div className="dropdown">
        <button className="dropdown-select" onClick={toggleDropdown}>
          {selectedPatch}
        </button>
        {isDropdownOpen && (
          <div className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
            {Object.keys(heroes).map((patch) => (
              <a
                key={patch}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  selectPatch(patch);
                }}
              >
                {patch}
              </a>
            ))}
          </div>
        )}
      </div>
      <HeroTable heroes={heroes[selectedPatch]} />
      <h6 style={{ color: "#cddcfe" }}>
        *Due to Valve disabling their API, this data is based off the closest
        possible estimates.
      </h6>
    </div>
  );
}

export default HeroesPage;

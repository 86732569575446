import React from "react";
import "./AnnouncementBar.css";

const AnnouncementBar = () => {
  return (
    <div className="announcement-bar">
      <p>
        Deadlock Guides is currently in beta. Please report any bugs in
        our&nbsp;
        <a href="https://discord.gg/CxQC86yuhJ">Discord Server.</a>
      </p>
    </div>
  );
};
export default AnnouncementBar;

import React from "react";
import Tabs from "../components/Tabs";
import "./ItemsPage.css"; // Import the CSS for styling

function ItemsPage() {
  return (
    <div className="items-page">
      <h1 style={{ fontSize: "36px", marginBottom: "0px" }}>Deadlock Items</h1>
      <h2
        style={{
          fontSize: "18px",
          color: "#cddcfe",
          marginTop: "20px",
          marginBottom: "40px",
        }}
      >
        All in-game items for Deadlock. Includes item names, type, win-rates,
        and pick-rates, with up to date stats, passives, actives, and costs.*
      </h2>

      <Tabs />
      <h6 style={{ color: "#cddcfe" }}>
        *Due to Valve disabling their API, this data is based off the closest
        possible estimates.
      </h6>
    </div>
  );
}

export default ItemsPage;

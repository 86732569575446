// itemDataWithPaths.jsx

const itemDataWithPaths = {
  "Tier 1": [
    {
      id: 1,
      name: "Infuser",
      type: "Spirit",
      winrate: 53.44,
      pickrate: "6.04%",
      path: "/itemicons/spirit/500/100px-Infuser.png",
      cost: 500,
      purchaseStats: [
        { name: "Bonus Health", value: 50 },
        { name: "Ammo", value: "10%" },
      ],
      active: {
        cooldown: 35,
        description: "Gain Spirit Power and Spirit Lifesteal",
        effects: [
          { name: "Spirit Power", value: 16 },
          { name: "Spirit Lifesteal", value: "20%" },
        ],
        duration: 6,
      },
    },
    {
      id: 2,
      name: "Extra Stamina",
      type: "Vitality",
      winrate: 53.38,
      pickrate: "17.97%",
      path: "/itemicons/vitality/500/100px-Extra_Stamina.png",
      cost: 500,
      purchaseStats: [
        { name: "Stamina", value: 1 },
        { name: "Stamina Recovery", value: "10%" },
        { name: "Fire Rate", value: "7%" },
      ],
      componentOf: "Superior Stamina",
    },
    {
      id: 3,
      name: "Healing Rite",
      type: "Vitality",
      winrate: 52.83,
      pickrate: "8.26%",
      path: "/itemicons/vitality/500/100px-Healing_Rite.png",
      cost: 500,
      purchaseStats: [
        { name: "Bonus Health", value: 45 },
        { name: "Spirit Power", value: 3 },
      ],
      active: {
        cooldown: 60,
        description:
          "Grant Regen and Sprint Speed to the target. Gets dispelled if you take damage from enemy players or objectives. Can be self-cast.",
        effects: [
          { name: "Total Health Regen", value: 370 },
          { name: "Sprint Speed", value: "+2m/s" },
        ],
        duration: 17,
        castRange: 30, // Added cast range
      },
      componentOf: "Health Nova",
    },
    {
      id: 4,
      name: "Mystic Reach",
      type: "Spirit",
      winrate: 52.79,
      pickrate: "16.83%",
      path: "/itemicons/spirit/500/100px-Mystic_Reach.png",
      cost: 500,
      purchaseStats: [
        { name: "Ability Range", value: "+16%" },
        { name: "Spirit Resist", value: "+6%" },
      ],
      componentOf: "Improved Reach",
    },
    {
      id: 5,
      name: "Rapid Rounds",
      type: "Weapon",
      winrate: 52.37,
      pickrate: "11.43%",
      path: "/itemicons/weapon/500/100px-Rapid_Rounds.png",
      cost: 500,
      purchaseStats: [
        { name: "Fire Rate", value: "+9%" },
        { name: "Sprint Speed", value: "+1m/s" },
      ],
    },
    {
      id: 6,
      name: "Enduring Spirit",
      type: "Vitality",
      winrate: 52.1,
      pickrate: "22.77%",
      path: "/itemicons/vitality/500/100px-Enduring_Spirit.png",
      cost: 500,
      purchaseStats: [
        { name: "Bonus Health", value: 75 },
        { name: "Spirit Lifesteal", value: "+10%" },
        { name: "Spirit Power", value: 4 },
      ],
    },
    {
      id: 7,
      name: "Close Quarters",
      type: "Weapon",
      winrate: 51.91,
      pickrate: "14.39%",
      path: "/itemicons/weapon/500/100px-Close_Quarters.png",
      cost: 500,
      purchaseStats: [{ name: "Bullet Shield Health", value: 85 }],
      passive: {
        description:
          "Deal additional Weapon Damage when in close range to your target.",
        effects: [{ name: "Weapon Damage", value: "+25%" }],
        condition: "Within 15m range",
      },
      componentOf: "Point Blank",
    },
    {
      id: 8,
      name: "Spirit Strike",
      type: "Spirit",
      winrate: 51.81,
      pickrate: "5.07%",
      path: "/itemicons/spirit/500/100px-Spirit_Strike.png",
      cost: 500,
      purchaseStats: [
        { name: "Spirit Shield Health", value: 80 },
        { name: "Health Regen", value: 0.8 },
        { name: "Melee Damage", value: "+12%" },
      ],
      passive: {
        cooldown: 8.5,
        description:
          "When you perform a Light or Heavy Melee attack against a hero, deal Extra Spirit Damage with the attack and reduce the target's Spirit Resist.",
        effects: [
          { name: "Spirit Damage", value: "x (scaling off Spirit)" },
          { name: "Spirit Resist Reduction", value: "-12%" },
        ],
        duration: 11,
      },
    },
    {
      id: 9,
      name: "Ammo Scavenger",
      type: "Spirit",
      winrate: 51.58,
      pickrate: "7.15%",
      path: "/itemicons/spirit/500/100px-Ammo_Scavenger.png",
      cost: 500,
      purchaseStats: [
        { name: "Ammo", value: "+15%" },
        { name: "Bonus Health", value: 60 },
      ],
      passive: {
        description:
          "Any time you secure or deny a Soul, you get ammo back and gain stacking Spirit Power.",
        effects: [
          { name: "", value: "2 Ammo Per Soul" },
          { name: "", value: "2 Spirit Power Per Soul" },
          { name: "", value: "12 Max Stacks" },
        ],
        duration: 35,
        maxStacks: 12,
      },
    },
    {
      id: 10,
      name: "Monster Rounds",
      type: "Weapon",
      winrate: 51.16,
      pickrate: "11.13%",
      path: "/itemicons/weapon/500/100px-Monster_Rounds.png",
      cost: 500,
      purchaseStats: [
        { name: "Weapon Damage vs. NPCs", value: "+35%" },
        { name: "Bullet Resist vs. NPCs", value: "+35%" },
        { name: "Bonus Health", value: 50 },
        { name: "Health Regen", value: 1 },
      ],
    },
    {
      id: 11,
      name: "Sprint Boots",
      type: "Vitality",
      winrate: 51.1,
      pickrate: "14.22%",
      path: "/itemicons/vitality/500/100px-Sprint_Boots.png",
      cost: 500,
      purchaseStats: [
        { name: "Sprint Speed", value: "+2m/s" },
        { name: "Health Regen", value: 1 },
        { name: "Weapon Damage", value: "+4%" },
      ],
      componentOf: "Enduring Speed",
    },
    {
      id: 12,
      name: "Basic Magazine",
      type: "Weapon",
      winrate: 51.05,
      pickrate: "20.12%",
      path: "/itemicons/weapon/500/100px-Basic_Magazine.png",
      cost: 500,
      purchaseStats: [
        { name: "Ammo", value: "+24%" },
        { name: "Weapon Damage", value: "+15%" },
      ],
      componentOf: "Titanic Magazine",
    },
    {
      id: 13,
      name: "Extra Charge",
      type: "Spirit",
      winrate: 51.0,
      pickrate: "16.60%",
      path: "/itemicons/spirit/500/100px-Extra_Charge.png",
      cost: 500,
      purchaseStats: [
        { name: "Bonus Ability Charges", value: 1 },
        { name: "CDR for Charged Abilities", value: "+10%" },
        { name: "Weapon Damage", value: "+6%" },
      ],
      componentOf: "Rapid Recharge",
    },
    {
      id: 14,
      name: "Restorative Shot",
      type: "Weapon",
      winrate: 50.45,
      pickrate: "7.80%",
      path: "/itemicons/weapon/500/100px-Restorative_Shot.png",
      cost: 500,
      purchaseStats: [
        { name: "Bullet Resist", value: "+7%" },
        { name: "Weapon Damage", value: "+8%" },
      ],
      passive: {
        cooldown: 6.2,
        description:
          "Your next bullet will heal you based on what target you hit.",
        effects: [
          { name: "Healing from Heroes", value: 40 },
          { name: "Healing from NPCs", value: 15 },
        ],
      },
    },
    {
      id: 15,
      name: "Headshot Booster",
      type: "Weapon",
      winrate: 50.39,
      pickrate: "14.00%",
      path: "/itemicons/weapon/500/100px-Headshot_Booster.png",
      cost: 500,
      purchaseStats: [
        { name: "Fire Rate", value: "+5%" },
        { name: "Bullet Shield Health", value: 40 },
      ],
      passive: {
        cooldown: 7.5,
        description: "Deal bonus Weapon Damage with headshots.",
        effects: [{ name: "Bonus Damage", value: 40 }],
      },
    },
    {
      id: 16,
      name: "Hollow Point Ward",
      type: "Weapon",
      winrate: 50.37,
      pickrate: "13.69%",
      path: "/itemicons/weapon/500/100px-Hollow_Point_Ward.png",
      cost: 500,
      purchaseStats: [
        { name: "Spirit Shield Health", value: 85 },
        { name: "Spirit Power", value: 4 },
      ],
      passive: {
        description:
          "When you are above 60% health, deal additional Weapon Damage.",
        effects: [{ name: "Conditional Weapon Damage", value: "+22%" }],
      },
    },
    {
      id: 17,
      name: "High-Velocity Mag",
      type: "Weapon",
      winrate: 50.1,
      pickrate: "14.33%",
      path: "/itemicons/weapon/500/100px-High-Velocity_Mag.png",
      cost: 500,
      purchaseStats: [
        { name: "Bullet Velocity", value: "+30%" },
        { name: "Weapon Damage", value: "+12%" },
        { name: "Bullet Shield Health", value: 50 },
      ],
      componentOf: "Headhunter",
    },
    {
      id: 18,
      name: "Melee Lifesteal",
      type: "Vitality",
      winrate: 50.06,
      pickrate: "3.24%",
      path: "/itemicons/vitality/500/100px-Melee_Lifesteal.png",
      cost: 500,
      purchaseStats: [
        { name: "Melee Damage", value: "+13%" },
        { name: "Bonus Health", value: 75 },
      ],
      passive: {
        cooldown: 7,
        description:
          "Your melee attacks heal you for 20% of the Melee Damage dealt plus 90.",
        effects: [
          {
            name: "vs. Heroes",
            value: "20% of Melee Damage + 90",
          },
          {
            name: "vs. NPC's",
            value: "40% effective",
          },
        ],
      },
      componentOf: "Lifestrike",
    },
    {
      id: 19,
      name: "Mystic Burst",
      type: "Spirit",
      winrate: 49.58,
      pickrate: "17.37%",
      path: "/itemicons/spirit/500/100px-Mystic_Burst.png",
      cost: 500,
      purchaseStats: [
        { name: "Spirit Shield Health", value: 40 },
        { name: "Weapon Damage", value: "+6%" },
      ],
      passive: {
        cooldown: 6,
        description:
          "Abilities deal bonus Spirit Damage if they deal 80 damage or more in a single hit.",
        effects: [{ name: "Bonus Spirit Damage", value: 35 }],
      },
      componentOf: "Improved Burst",
    },
    {
      id: 20,
      name: "Extra Health",
      type: "Vitality",
      winrate: 49.26,
      pickrate: "10.97%",
      path: "/itemicons/vitality/500/100px-Extra_Health.png",
      cost: 500,
      purchaseStats: [
        { name: "Bonus Health", value: 160 },
        { name: "Weapon Damage", value: "+5%" },
      ],
    },
    {
      id: 21,
      name: "Extra Regen",
      type: "Vitality",
      winrate: 48.62,
      pickrate: "17.35%",
      path: "/itemicons/vitality/500/100px-Extra_Regen.png",
      cost: 500,
      purchaseStats: [
        { name: "Health Regen", value: 2.8 },
        { name: "Ammo", value: "+8%" },
        { name: "Bonus Health", value: 25 },
      ],
    },
    {
      id: 22,
      name: "Extra Spirit",
      type: "Spirit",
      winrate: 48.54,
      pickrate: "15.06%",
      path: "/itemicons/spirit/500/100px-Extra_Spirit.png",
      cost: 500,
      purchaseStats: [
        { name: "Spirit Power", value: 9 },
        { name: "Health Regen", value: 1 },
        { name: "Bonus Health", value: 35 },
      ],
      componentOf: "Improved Spirit",
    },
  ],
  "Tier 2": [
    {
      id: 2,
      name: "Fleetfoot",
      type: "Weapon",
      winrate: 54.25,
      pickrate: "5.94%",
      path: "/itemicons/weapon/1250/100px-Fleetfoot.png",
      cost: 1250,
      purchaseStats: [
        { name: "Bonus Health", value: 90 },
        { name: "Slide Distance", value: "+25%" },
      ],
      passive: {
        description: "Removes the Movement Speed penalty while shooting.",
      },
      active: {
        cooldown: 19,
        description: "Gain bonus Movement Speed and Ammo.",
        effects: [
          { name: "Movement Speed", value: "+3m/s" },
          { name: "Temporary Ammo", value: "+30%" },
        ],
        duration: 4,
      },
    },
    {
      id: 3,
      name: "Enduring Speed",
      type: "Vitality",
      winrate: 54.01,
      pickrate: "25.08%",
      path: "/itemicons/vitality/1250/100px-Enduring_Speed.png",
      cost: 1250,
      components: ["Sprint Boots"],
      purchaseStats: [
        { name: "Move Speed", value: "+1.4m/s" },
        { name: "Sprint Speed", value: "+2m/s" },
        { name: "Bonus Health", value: 75 },
        { name: "Health Regen", value: 1.5 },
      ],
      passive: {
        description: "Reduces the effects of enemy Movement Slow.",
        effects: [{ name: "Movement Slow Resist", value: "+40%" }],
      },
    },
    {
      id: 4,
      name: "Duration Extender",
      type: "Spirit",
      winrate: 53.38,
      pickrate: "9.41%",
      path: "/itemicons/spirit/1250/100px-Duration_Extender.png",
      cost: 1250,
      passive: {
        description: "Increases the Duration of your abilities and items.",
        effects: [
          { name: "Ability Duration", value: "+16%" },
          { name: "Bonus Health", value: 100 },
          { name: "Health Regen", value: "+1.75" },
        ],
      },
      componentOf: "Superior Duration",
    },
    {
      id: 5,
      name: "Melee Charge",
      type: "Weapon",
      winrate: 53.25,
      pickrate: "3.84%",
      path: "/itemicons/weapon/1250/100px-Melee_Charge.png",
      cost: 1250,
      purchaseStats: [
        { name: "Weapon Damage", value: "+15%" },
        { name: "Bonus Health", value: 100 },
        { name: "Health Regen", value: 1 },
        { name: "Heavy Melee Distance", value: "40%" },
      ],
      passive: {
        cooldown: 8.5,
        description:
          "Increases your Heavy Melee charge distance and reloads instantly on Heavy Melee attacks.",
        effects: [{ name: "Bonus Heavy Melee Damage", value: "+20%" }],
      },
    },
    {
      id: 6,
      name: "Mystic Vulnerability",
      type: "Spirit",
      winrate: 53.03,
      pickrate: "14.48%",
      path: "/itemicons/spirit/1250/100px-Mystic_Vulnerability.png",
      cost: 1250,
      purchaseStats: [{ name: "Spirit Resist", value: "+6%" }],
      passive: {
        description:
          "When the target takes Spirit Damage, they have their Spirit Resist reduced.",
        effects: [{ name: "Spirit Resist Reduction", value: "-12%" }],
        duration: 6,
      },
      componentOf: "Escalating Exposure",
    },
    {
      id: 7,
      name: "Slowing Hex",
      type: "Spirit",
      winrate: 52.86,
      pickrate: "3.59%",
      path: "/itemicons/spirit/1250/100px-Slowing_Hex.png",
      cost: 1250,
      purchaseStats: [
        { name: "Sprint Speed", value: "+1m/s" },
        { name: "Spirit Power", value: 5 },
      ],
      active: {
        cooldown: 25,
        description:
          "Deals Spirit Damage, Slows targets movement and dashes, and Silences their movement-based items and abilities.",
        effects: [
          { name: "Spirit Damage", value: "70 + (0.59 * Spirit Power)" },
          { name: "Movement Slow", value: "30%" },
        ],
        castRange: 25,
        duration: 3,
      },
    },
    {
      id: 8,
      name: "Spirit Lifesteal",
      type: "Vitality",
      winrate: 52.78,
      pickrate: "20.65%",
      path: "/itemicons/vitality/1250/100px-Spirit_Lifesteal.png",
      cost: 1250,
      purchaseStats: [
        { name: "Spirit Lifesteal", value: "+23%" },
        { name: "Bonus Health", value: 75 },
      ],
    },
    {
      id: 9,
      name: "Restorative Locket",
      type: "Vitality",
      winrate: 52.5,
      pickrate: "0.76%",
      path: "/itemicons/vitality/1250/100px-Restorative_Locket.png",
      cost: 1250,
      purchaseStats: [
        { name: "Spirit Resist", value: "+8%" },
        { name: "Spirit Power", value: 5 },
      ],
      passive: {
        description:
          "When an enemy uses an ability within 50m range, store one Restoration Stack.",
        effects: [{ name: "Restoration Stack", value: "Up to 15 stacks" }],
      },
      active: {
        cooldown: 28,
        description:
          "Consume all stacks to heal target ally. Replenishes a stamina point at max stacks.",
        effects: [
          { name: "Heal per Stack", value: 35 },
          { name: "Max Stacks", value: 15 },
        ],
      },
    },
    {
      id: 10,
      name: "Health Nova",
      type: "Vitality",
      winrate: 52.36,
      pickrate: "3.04%",
      path: "/itemicons/vitality/1250/100px-Health_Nova.png",
      cost: 1250,
      components: ["Healing Rite"],
      purchaseStats: [
        { name: "Weapon Damage", value: "+10%" },
        { name: "Bonus Health", value: 100 },
        { name: "Spirit Power", value: 4 },
      ],
      active: {
        cooldown: 60,
        description: "Heal yourself and nearby allies.",
        effects: [
          { name: "Total HP Regen", value: 260 },
          { name: "Radius", value: "15m" },
          { name: "Additional Ally Healing", value: "+20%" },
        ],
        duration: 2,
      },
    },
    {
      id: 11,
      name: "Bullet Resist Shredder",
      type: "Spirit",
      winrate: 52.12,
      pickrate: "16.82%",
      path: "/itemicons/spirit/1250/100px-Bullet_Resist_Shredder.png",
      cost: 1250,
      purchaseStats: [
        { name: "Bonus Health", value: 100 },
        { name: "Bullet Resist", value: "+5%" },
      ],
      passive: {
        description:
          "Reduces Bullet Resist on enemies when you deal Spirit Damage.",
        effects: [{ name: "Bullet Resist Reduction", value: "-12%" }],
        duration: 8,
      },
    },
    {
      id: 12,
      name: "Active Reload",
      type: "Weapon",
      winrate: 51.95,
      pickrate: "13.37%",
      path: "/itemicons/weapon/1250/100px-Active_Reload.png",
      cost: 1250,
      purchaseStats: [
        { name: "Weapon Damage", value: "+10%" },
        { name: "Reload Time", value: "-20%" },
      ],
      passive: {
        cooldown: 18,
        description:
          "Instantly finish reload and grant Fire Rate and Bullet Lifesteal when pressing [R] during highlighted portion.",
        effects: [
          { name: "Fire Rate", value: "+20% (Conditional)" },
          { name: "Bullet Lifesteal", value: "+20% (Conditional)" },
        ],
        duration: 7,
      },
    },
    {
      id: 13,
      name: "Debuff Reducer",
      type: "Vitality",
      winrate: 51.82,
      pickrate: "4.06%",
      path: "/itemicons/vitality/1250/100px-Debuff_Reducer.png",
      cost: 1250,
      purchaseStats: [
        { name: "Bonus Health", value: 75 },
        { name: "Weapon Damage", value: "+6%" },
      ],
      passive: {
        description:
          "Reduces the duration of all negative effects applied to you.",
        effects: [{ name: "Debuff Resist", value: "30%" }],
      },
      componentOf: "Debuff Remover",
    },
    {
      id: 14,
      name: "Improved Cooldown",
      type: "Spirit",
      winrate: 51.73,
      pickrate: "12.86%",
      path: "/itemicons/spirit/1250/100px-Improved_Cooldown.png",
      cost: 1250,
      passive: {
        description: "Reduces the Cooldown of your abilities and items.",
        effects: [
          { name: "Cooldown Reduction", value: "+16%" },
          { name: "Health Regen", value: "+1.5" },
        ],
      },
      componentOf: "Superior Cooldown",
    },
    {
      id: 15,
      name: "Withering Whip",
      type: "Spirit",
      winrate: 51.57,
      pickrate: "2.63%",
      path: "/itemicons/spirit/1250/100px-Withering_Whip.png",
      cost: 1250,
      purchaseStats: [
        { name: "Spirit Power", value: 4 },
        { name: "Fire Rate", value: "+8%" },
        { name: "Bonus Health", value: 50 },
      ],
      active: {
        cooldown: 26,
        description:
          "Target an enemy to apply Fire Rate Slow and reduce their Bullet Resist.",
        effects: [
          { name: "Fire Rate Slow", value: "-40%" },
          { name: "Bullet Resist Reduction", value: "-14%" },
        ],
        castRange: 24,
        duration: 4.5,
      },
    },
    {
      id: 16,
      name: "Kinetic Dash",
      type: "Weapon",
      winrate: 51.44,
      pickrate: "5.51%",
      path: "/itemicons/weapon/1250/100px-Kinetic_Dash.png",
      cost: 1250,
      purchaseStats: [
        { name: "Bonus Health", value: 100 },
        { name: "Health Regen", value: 1.5 },
      ],
      passive: {
        cooldown: 10.5,
        description:
          "Next Dash-Jump doesn't cost extra stamina, granting Fire Rate and bonus Ammo until next reload.",
        effects: [
          { name: "Fire Rate", value: "+25%" },
          { name: "Temporary Ammo", value: 5 },
        ],
        duration: 8,
      },
    },
    {
      id: 17,
      name: "Bullet Lifesteal",
      type: "Vitality",
      winrate: 51.39,
      pickrate: "22.45%",
      path: "/itemicons/vitality/1250/100px-Bullet_Lifesteal.png",
      cost: 1250,
      purchaseStats: [
        { name: "Bullet Lifesteal", value: "+28%" },
        { name: "Bonus Health", value: 75 },
      ],
    },
    {
      id: 18,
      name: "Suppressor",
      type: "Spirit",
      winrate: 51.39,
      pickrate: "9.85%",
      path: "/itemicons/spirit/1250/100px-Suppressor.png",
      cost: 1250,
      purchaseStats: [
        { name: "Bonus Health", value: 50 },
        { name: "Health Regen", value: 2.5 },
        { name: "Spirit Power", value: 4 },
      ],
      passive: {
        description: "Dealing Spirit Damage applies Fire Rate Slow to enemies.",
        effects: [{ name: "Fire Rate Slow", value: "-25%" }],
        duration: 2,
      },
      componentOf: "Mystic Slow",
    },
    {
      id: 20,
      name: "Berserker",
      type: "Weapon",
      winrate: 51.03,
      pickrate: "8.47%",
      path: "/itemicons/weapon/1250/100px-Berserker.png",
      cost: 1250,
      purchaseStats: [
        { name: "Ammo", value: 4 },
        { name: "Bullet Resist", value: "+7%" },
      ],
      passive: {
        description: "Weapon Damage increases as you take sustained damage.",
        effects: [
          { name: "Weapon Damage Increase", value: "+5% per Stack" },
          { name: "Damage to Stack", value: 110 },
          { name: "Max Stacks", value: 10 },
        ],
        duration: 10,
      },
    },
    {
      id: 21,
      name: "Spirit Armor",
      type: "Vitality",
      winrate: 50.97,
      pickrate: "16.51%",
      path: "/itemicons/vitality/1250/100px-Spirit_Armor.png",
      cost: 1250,
      purchaseStats: [
        { name: "Spirit Resist", value: "+20%" },
        { name: "Spirit Power", value: 5 },
      ],
      componentOf: "Improved Spirit Armor",
    },
    {
      id: 22,
      name: "Healbane",
      type: "Vitality",
      winrate: 50.85,
      pickrate: "8.16%",
      path: "/itemicons/vitality/1250/100px-Healbane.png",
      cost: 1250,
      purchaseStats: [{ name: "Bonus Health", value: 75 }],
      passive: {
        description:
          "Your Spirit Damage applies Healing Reduction. Receive a large heal on hero kill.",
        effects: [
          { name: "Healing Reduction", value: "40%" },
          { name: "Hero Kill Heal", value: 350 },
        ],
        duration: 6,
      },
    },
    {
      id: 23,
      name: "Swift Striker",
      type: "Weapon",
      winrate: 50.36,
      pickrate: "7.81%",
      path: "/itemicons/weapon/1250/100px-Swift_Striker.png",
      cost: 1250,
      purchaseStats: [
        { name: "Fire Rate", value: "+22%" },
        { name: "Ammo", value: "+10%" },
        { name: "Bullet Resist", value: "-5%" },
      ],
    },
    {
      id: 24,
      name: "Bullet Armor",
      type: "Vitality",
      winrate: 50.31,
      pickrate: "17.51%",
      path: "/itemicons/vitality/1250/100px-Bullet_Armor.png",
      cost: 1250,
      purchaseStats: [
        { name: "Bullet Resist", value: "+20%" },
        { name: "Weapon Damage", value: "+6%" },
      ],
      componentOf: "Improved Bullet Armor",
    },
    {
      id: 25,
      name: "Reactive Barrier",
      type: "Vitality",
      winrate: 49.96,
      pickrate: "4.30%",
      path: "/itemicons/vitality/1250/100px-Reactive_Barrier.png",
      cost: 1250,
      purchaseStats: [
        { name: "Ammo", value: "+15%" },
        { name: "Bonus Health", value: 75 },
        { name: "Health Regen", value: 2 },
      ],
      passive: {
        cooldown: 30,
        description:
          "Deploy temporary Bullet and Spirit Shields when movement locked, Stunned, Chained, Immobilized, or Slept.",
        effects: [
          { name: "Bullet Shield Health", value: 400 },
          { name: "Spirit Shield Health", value: 200 },
        ],
        duration: 9,
      },
    },
    {
      id: 26,
      name: "Cold Front",
      type: "Spirit",
      winrate: 49.86,
      pickrate: "3.57%",
      path: "/itemicons/spirit/1250/100px-Cold_Front.png",
      cost: 1250,
      purchaseStats: [{ name: "Spirit Resist", value: "+10%" }],
      active: {
        cooldown: 32,
        description:
          "Release an expanding ice blast dealing Spirit Damage and Slowing targets.",
        effects: [
          { name: "Spirit Damage", value: "75 + (1.5 * Spirit Power)" },
          { name: "Movement Slow", value: "40%" },
        ],
        endRadius: 14,
        duration: 3,
      },
    },
    {
      id: 27,
      name: "Quicksilver Reload",
      type: "Spirit",
      winrate: 49.83,
      pickrate: "14.39%",
      path: "/itemicons/spirit/1250/100px-Quicksilver_Reload.png",
      cost: 1250,
      passive: {
        description:
          "Imbue an ability with bonus Spirit Damage, reloads weapon, and grants Fire Rate bonus.",
        effects: [
          { name: "Spirit Damage", value: 65 },
          { name: "Fire Rate Bonus", value: "15%" },
        ],
        cooldown: 12,
      },
    },
    {
      id: 28,
      name: "Slowing Bullets",
      type: "Weapon",
      winrate: 49.82,
      pickrate: "12.16%",
      path: "/itemicons/weapon/1250/100px-Slowing_Bullets.png",
      cost: 1250,
      purchaseStats: [
        { name: "Weapon Damage", value: "+16%" },
        { name: "Spirit Power", value: 5 },
      ],
      passive: {
        description: "Bullets apply Movement Slow.",
        effects: [{ name: "Movement Slow", value: "25%" }],
        duration: 1.3,
      },
      componentOf: "Silencer",
    },
    {
      id: 29,
      name: "Soul Shredder Bullets",
      type: "Weapon",
      winrate: 49.71,
      pickrate: "19.76%",
      path: "/itemicons/weapon/1250/100px-Soul_Shredder_Bullets.png",
      cost: 1250,
      purchaseStats: [{ name: "Weapon Damage", value: "+7%" }],
      passive: {
        description:
          "Bullets apply debuff amplifying Spirit Damage and granting Spirit Lifesteal.",
        effects: [
          { name: "Spirit Damage Amplification", value: "10%" },
          { name: "Spirit Lifesteal", value: "20%" },
        ],
        debuffDuration: 6,
      },
    },
    {
      id: 30,
      name: "Enchanter's Barrier",
      type: "Vitality",
      winrate: 49.23,
      pickrate: "12.25%",
      path: "/itemicons/vitality/1250/100px-Enchanter's_Barrier.png",
      cost: 1250,
      purchaseStats: [{ name: "Spirit Shield Health", value: 300 }],
      passive: {
        description:
          "Gain bonus Spirit Power and Cooldown Reduction while shielded.",
        effects: [
          { name: "Spirit Power", value: "+20" },
          { name: "Cooldown Reduction", value: "+8%" },
        ],
      },
    },
    {
      id: 31,
      name: "Mystic Shot",
      type: "Weapon",
      winrate: 48.16,
      pickrate: "15.13%",
      path: "/itemicons/weapon/1250/100px-Mystic_Shot.png",
      cost: 1250,
      purchaseStats: [
        { name: "Weapon Damage", value: "+12%" },
        { name: "Spirit Power", value: 4 },
      ],
      passive: {
        cooldown: 5.2,
        description: "Next bullet deals bonus Spirit Damage.",
        effects: [
          { name: "Spirit Damage", value: "65 + (0.62 * SpiritPower)" },
        ],
      },
    },
    {
      id: 32,
      name: "Divine Barrier",
      type: "Vitality",
      winrate: 47.99,
      pickrate: "0.54%",
      path: "/itemicons/vitality/1250/100px-Divine_Barrier.png",
      cost: 1250,
      purchaseStats: [
        { name: "Sprint Speed", value: "+1m/s" },
        { name: "Health", value: 75 },
        { name: "Ability Range", value: "+8%" },
      ],
      active: {
        cooldown: 28,
        description:
          "Provide target with Bullet Shield, Spirit Shield, and Movement Speed.",
        effects: [
          { name: "Bullet Shield", value: 300 },
          { name: "Spirit Shield", value: 300 },
          { name: "Movement Speed", value: "+2m/s" },
        ],
        castRange: 35,
        duration: 5,
      },
    },
    {
      id: 33,
      name: "Combat Barrier",
      type: "Vitality",
      winrate: 47.29,
      pickrate: "10.16%",
      path: "/itemicons/vitality/1250/100px-Combat_Barrier.png",
      cost: 1250,
      purchaseStats: [{ name: "Bullet Shield Health", value: 300 }],
      passive: {
        description: "Gain Weapon Damage and Fire Rate while shielded.",
        effects: [
          { name: "Weapon Damage", value: "+28%" },
          { name: "Fire Rate", value: "+8%" },
        ],
      },
    },
    {
      id: 34,
      name: "Decay",
      type: "Spirit",
      winrate: 47.05,
      pickrate: "1.06%",
      path: "/itemicons/spirit/1250/100px-Decay.png",
      cost: 1250,
      purchaseStats: [{ name: "Spirit Power", value: 7 }],
      active: {
        cooldown: 30,
        description:
          "Reduce healing received and inflict damage over time on targeted enemy.",
        effects: [
          { name: "Healing Reduction", value: "-50%" },
          { name: "Bleed Damage", value: "3.1%/sec" },
        ],
        castRange: "15m + (0.20 * SpiritPower)",
        duration: 10,
      },
    },
    {
      id: 35,
      name: "Long Range",
      type: "Weapon",
      winrate: 46.05,
      pickrate: "4.33%",
      path: "/itemicons/weapon/1250/100px-Long_Range.png",
      cost: 1250,
      purchaseStats: [
        { name: "Ammo", value: "+20%" },
        { name: "Bullet Shield Health", value: 140 },
      ],
      passive: {
        description: "Deal additional Weapon Damage beyond minimum distance.",
        effects: [{ name: "Weapon Damage", value: "+40%" }],
        condition: "Beyond 15m distance",
      },
      componentOf: "Sharpshooter",
    },
    {
      id: 36,
      name: "Return Fire",
      type: "Vitality",
      winrate: 44.87,
      pickrate: "0.91%",
      path: "/itemicons/vitality/1250/100px-Return_Fire.png",
      cost: 1250,
      purchaseStats: [
        { name: "Bonus Health", value: 125 },
        { name: "Spirit Power", value: 7 },
        { name: "Fire Rate", value: "+7%" },
      ],
      active: {
        cooldown: 24,
        description:
          "Automatically fire a bullet towards attackers who damage you.",
        effects: [
          { name: "Bullet Damage Returned", value: "70%" },
          { name: "Spirit Damage Returned", value: "30%" },
        ],
        duration: 7,
      },
    },
  ],
  "Tier 3": [
    {
      id: 1,
      name: "Superior Duration",
      type: "Spirit",
      winrate: 54.87,
      pickrate: "5.11%",
      path: "/itemicons/spirit/3000/100px-Superior_Duration.png",
      cost: 3500,
      components: ["Duration Extender"],
      purchaseStats: [
        { name: "Imbued Ability Duration", value: "+32%" },
        { name: "Non-Imbued Ability Duration", value: "+26%" },
        { name: "Bonus Health", value: 175 },
        { name: "Weapon Damage", value: "+15%" },
        { name: "Health Regen", value: 4 },
      ],
    },
    {
      id: 2,
      name: "Heroic Aura",
      type: "Weapon",
      winrate: 54.49,
      pickrate: "2.37%",
      path: "/itemicons/weapon/3000/100px-Heroic_Aura.png",
      cost: 3000,
      purchaseStats: [
        { name: "Bullet Lifesteal", value: "+20%" },
        { name: "Bonus Health", value: 150 },
        { name: "Move Speed", value: "+1m/s" },
      ],
      passive: {
        description: "Provides Fire Rate to nearby player minions.",
        effects: [{ name: "Minion Fire Rate", value: "+40%" }],
        radius: 20,
        condition: "Nearby player minions",
      },
      active: {
        cooldown: 32,
        description:
          "Provides Movement Speed and Fire Rate to you and nearby allies.",
        effects: [
          { name: "Movement Speed", value: "+2m/s" },
          { name: "Fire Rate", value: "+25%" },
        ],
        radius: 30,
        duration: 6,
      },
    },
    {
      id: 3,
      name: "Torment Pulse",
      type: "Spirit",
      winrate: 54.47,
      pickrate: "4.88%",
      path: "/itemicons/spirit/3000/100px-Torment_Pulse.png",
      cost: 3000,
      purchaseStats: [
        { name: "Bonus Health", value: 140 },
        { name: "Spirit Power", value: 6 },
      ],
      passive: {
        cooldown: 1.8,
        description: "Periodically deals Spirit Damage to nearby enemies.",
        effects: [
          { name: "Spirit Damage", value: "40 + (0.24 * Spirit Power)" },
        ],
        radius: 9,
      },
    },
    {
      id: 4,
      name: "Improved Reach",
      type: "Spirit",
      winrate: 54.26,
      pickrate: "8.46%",
      path: "/itemicons/spirit/3000/100px-Improved_Reach.png",
      cost: 3500,
      components: ["Mystic Reach"],
      purchaseStats: [
        { name: "Imbued Ability Range", value: "+35%" },
        { name: "Non-Imbued Ability Range", value: "+25%" },
        { name: "Spirit Resist", value: "+10%" },
        { name: "Spirit Power", value: 8 },
      ],
    },
    {
      id: 5,
      name: "Hunter's Aura",
      type: "Weapon",
      winrate: 54.13,
      pickrate: "3.30%",
      path: "/itemicons/weapon/3000/100px-Hunter's_Aura.png",
      cost: 3000,
      purchaseStats: [{ name: "Bonus Health", value: 150 }],
      passive: {
        description: "Reduces nearby enemies' Bullet Resist and Fire Rate.",
        effects: [
          { name: "Bullet Resist Reduction", value: "-9%" },
          { name: "Fire Rate Slow", value: "10%" },
        ],
        radius: 15,
        notes: "Effect triples if only one enemy hero is nearby",
      },
    },
    {
      id: 6,
      name: "Lifestrike",
      type: "Vitality",
      winrate: 54.08,
      pickrate: "2.67%",
      path: "/itemicons/vitality/3000/100px-Lifestrike.png",
      cost: 3500,
      components: ["Melee Lifesteal"],
      purchaseStats: [
        { name: "Melee Damage", value: "+40%" },
        { name: "Bonus Health", value: 125 },
        { name: "Bullet Resist", value: "+8%" },
      ],
      passive: {
        cooldown: 5,
        description: "Melee Attack applies Movement Slow and heals you.",
        effects: [
          { name: "Movement Slow", value: "60%" },
          { name: "Heal", value: "55% of Melee Damage dealt + 110" },
        ],
        slowDuration: 2.5,
        notes: "Heal is 40% effective vs non-heroes",
      },
    },
    {
      id: 7,
      name: "Majestic Leap",
      type: "Vitality",
      winrate: 53.74,
      pickrate: "2.25%",
      path: "/itemicons/vitality/3000/100px-Majestic_Leap.png",
      cost: 3000,
      purchaseStats: [
        { name: "Spirit Power", value: 10 },
        { name: "Bonus Health", value: 125 },
      ],
      active: {
        cooldown: 24,
        description: "Launch yourself high into the air.",
        notes:
          "Can be used again to drop down faster. Disabled for 5s if attacked by enemy Hero.",
      },
    },
    {
      id: 8,
      name: "Intensifying Magazine",
      type: "Weapon",
      winrate: 52.8,
      pickrate: "7.61%",
      path: "/itemicons/weapon/3000/100px-Intensifying_Magazine.png",
      cost: 3000,
      purchaseStats: [
        { name: "Ammo", value: "+25%" },
        { name: "Weapon Damage", value: "+20%" },
      ],
      passive: {
        description:
          "Increases Weapon Damage as you continuously fire your weapon.",
        effects: [{ name: "Max Weapon Damage", value: "75%" }],
      },
    },
    {
      id: 9,
      name: "Rescue Beam",
      type: "Vitality",
      winrate: 52.65,
      pickrate: "0.82%",
      path: "/itemicons/vitality/3000/100px-Rescue_Beam.png",
      cost: 3000,
      purchaseStats: [
        { name: "Bonus Health", value: 125 },
        { name: "Sprint Speed", value: "+2m/s" },
        { name: "Spirit Power", value: 9 },
      ],
      active: {
        cooldown: 25,
        description: "Heals a target allied hero and yourself.",
        effects: [{ name: "Heal Amount", value: "26% of Max Health" }],
        channelDuration: 2.5,
        castRange: 36,
        notes:
          "Can Pull target towards you once while healing. Can be self-cast.",
      },
    },
    {
      id: 10,
      name: "Point Blank",
      type: "Weapon",
      winrate: 52.39,
      pickrate: "9.38%",
      path: "/itemicons/weapon/3000/100px-Point_Blank.png",
      cost: 3500,
      components: ["Close Quarters"],
      purchaseStats: [
        { name: "Bullet Resist", value: "+15%" },
        { name: "Stamina", value: "+1" },
      ],
      passive: {
        description:
          "Gain Weapon Damage and apply Movement Slow in close range.",
        effects: [
          { name: "Weapon Damage", value: "+45%" },
          { name: "Movement Slow", value: "25%" },
        ],
        slowDuration: 2,
        condition: "Within 15m range of target",
      },
    },
    {
      id: 11,
      name: "Tesla Bullets",
      type: "Weapon",
      winrate: 52.28,
      pickrate: "12.80%",
      path: "/itemicons/weapon/3000/100px-Tesla_Bullets.png",
      cost: 3000,
      purchaseStats: [{ name: "Fire Rate", value: "+14%" }],
      passive: {
        description:
          "Bullets have a chance to shock target and jump to nearby enemies.",
        effects: [
          { name: "Shock Damage", value: "30 + (0.13 * SpiritPower)" },
          { name: "Jump Damage", value: "60 + (0.2 * SpiritPower)" },
        ],
        procChance: 25,
        jumpRadius: 8,
        maxJumps: 2,
      },
    },
    {
      id: 12,
      name: "Superior Cooldown",
      type: "Spirit",
      winrate: 51.97,
      pickrate: "15.01%",
      path: "/itemicons/spirit/3000/100px-Superior_Cooldown.png",
      cost: 4250,
      purchaseStats: [
        { name: "Imbued Ability CDR", value: "+32%" },
        { name: "Non-Imbued Ability CDR", value: "+24%" },
        { name: "Health Regen", value: 4 },
      ],
    },
    {
      id: 13,
      name: "Titanic Magazine",
      type: "Weapon",
      winrate: 51.9,
      pickrate: "10.39%",
      path: "/itemicons/weapon/3000/100px-Titanic_Magazine.png",
      cost: 3500,
      components: ["Basic Magazine"],
      purchaseStats: [
        { name: "Ammo", value: "+100%" },
        { name: "Weapon Damage", value: "+18%" },
        { name: "Bullet Resist", value: "+18%" },
      ],
    },
    {
      id: 14,
      name: "Pristine Emblem",
      type: "Weapon",
      winrate: 51.55,
      pickrate: "8.00%",
      path: "/itemicons/weapon/3000/100px-Pristine_Emblem.png",
      cost: 3000,
      purchaseStats: [
        { name: "Weapon Damage", value: "+25%" },
        { name: "Spirit Power", value: 12 },
        { name: "Spirit Resist", value: "+12%" },
      ],
      passive: {
        description:
          "Deals additional Weapon Damage to enemies above 50% health.",
        effects: [{ name: "Weapon Damage", value: "+25%" }],
      },
    },
    {
      id: 15,
      name: "Veil Walker",
      type: "Vitality",
      winrate: 51.39,
      pickrate: "3.02%",
      path: "/itemicons/vitality/3000/100px-Veil_Walker.png",
      cost: 3000,
      purchaseStats: [
        { name: "Bullet Shield Health", value: 200 },
        { name: "Spirit Shield Health", value: 200 },
        { name: "Ammo", value: "+12%" },
        { name: "Weapon Damage", value: "+10%" },
      ],
      passive: {
        cooldown: 17,
        description:
          "Walking through cosmic veil grants Stealth, Movement Speed, shield restoration, and Fire Rate bonus.",
        effects: [
          { name: "Stealth", value: "Invisible" },
          { name: "Movement Speed", value: "+3m/s" },
          { name: "Fire Rate", value: "+20%" },
        ],
        duration: 5,
      },
    },
    {
      id: 16,
      name: "Surge of Power",
      type: "Spirit",
      winrate: 51.3,
      pickrate: "5.03%",
      path: "/itemicons/spirit/3000/100px-Surge_of_Power.png",
      cost: 3000,
      purchaseStats: [{ name: "Bonus Health", value: 75 }],
      passive: {
        cooldown: 10.5,
        description:
          "Imbue ability with Spirit Power, granting Movement Speed and Fire Rate bonus on use.",
        effects: [
          { name: "Spirit Power", value: 34 },
          { name: "Fire Rate", value: "+10%" },
          { name: "Movement Speed", value: "+3m/s" },
        ],
        duration: 6,
      },
    },
    {
      id: 17,
      name: "Warp Stone",
      type: "Weapon",
      winrate: 51.2,
      pickrate: "4.78%",
      path: "/itemicons/weapon/3000/100px-Warp_Stone.png",
      cost: 3000,
      purchaseStats: [
        { name: "Weapon Damage", value: "+20%" },
        { name: "Spirit Power", value: 8 },
      ],
      active: {
        cooldown: 15,
        description: "Teleport straight ahead, gaining Bullet Resist.",
        effects: [
          { name: "Teleport Range", value: 11 },
          { name: "Bullet Resist", value: "+30%" },
        ],
        duration: 5,
      },
    },
    {
      id: 18,
      name: "Improved Spirit",
      type: "Spirit",
      winrate: 50.9,
      pickrate: "12.55%",
      path: "/itemicons/spirit/3000/100px-Improved_Spirit.png",
      cost: 3500,
      components: ["Extra Spirit"],
      purchaseStats: [
        { name: "Spirit Power", value: 21 },
        { name: "Health Regen", value: 2 },
        { name: "Sprint Speed", value: "+1m/s" },
        { name: "Bonus Health", value: 75 },
      ],
      isComponentOf: ["Boundless Spirit"],
    },
    {
      id: 19,
      name: "Mystic Slow",
      type: "Spirit",
      winrate: 50.89,
      pickrate: "5.39%",
      path: "/itemicons/spirit/3000/100px-Mystic_Slow.png",
      cost: 4250,
      components: ["Suppressor"],
      purchaseStats: [
        { name: "Bonus Health", value: 100 },
        { name: "Health Regen", value: 3 },
        { name: "Spirit Power", value: 6 },
      ],
      passive: {
        description:
          "Reduces Movement Speed and Fire Rate of targets taking Spirit damage.",
        effects: [
          { name: "Movement Slow", value: "+30%" },
          { name: "Fire Rate Slow", value: "+40%" },
        ],
        duration: 2,
      },
    },
    {
      id: 20,
      name: "Burst Fire",
      type: "Weapon",
      winrate: 50.71,
      pickrate: "8.04%",
      path: "/itemicons/weapon/3000/100px-Burst_Fire.png",
      cost: 3000,
      purchaseStats: [
        { name: "Fire Rate", value: "+12%" },
        { name: "Slide Distance", value: "+50%" },
        { name: "Bonus Health", value: 70 },
      ],
      passive: {
        cooldown: 8.5,
        description:
          "Gain Fire Rate and Move Speed when hitting enemy heroes with bullets.",
        effects: [
          { name: "Fire Rate", value: "+30%" },
          { name: "Move Speed", value: "+2m/s" },
        ],
        duration: 4,
      },
    },
    {
      id: 21,
      name: "Improved Spirit Armor",
      type: "Vitality",
      winrate: 50.67,
      pickrate: "11.09%",
      path: "/itemicons/vitality/3000/100px-Improved_Spirit_Armor.png",
      cost: 4250,
      purchaseStats: [
        { name: "Spirit Resist", value: "+45%" },
        { name: "Spirit Power", value: 8 },
      ],
    },
    {
      id: 22,
      name: "Superior Stamina",
      type: "Vitality",
      winrate: 50.34,
      pickrate: "8.41%",
      path: "/itemicons/vitality/3000/100px-Superior_Stamina.png",
      cost: 3500,
      components: ["Extra Stamina"],
      purchaseStats: [
        { name: "Stamina", value: 3 },
        { name: "Stamina Recovery", value: "+20%" },
        { name: "Air Jump/Dash Distance", value: "+30%" },
        { name: "Fire Rate", value: "+7%" },
        { name: "Spirit Power", value: 8 },
      ],
      passive: {
        description:
          "Increases Air Dashes and Air Jumps before landing from 1 to 2.",
      },
    },
    {
      id: 23,
      name: "Improved Bullet Armor",
      type: "Vitality",
      winrate: 49.89,
      pickrate: "12.86%",
      path: "/itemicons/vitality/3000/100px-Improved_Bullet_Armor.png",
      cost: 4250,
      components: ["Bullet Armor"],
      purchaseStats: [
        { name: "Bullet Resist", value: "+45%" },
        { name: "Weapon Damage", value: "+10%" },
      ],
    },
    {
      id: 24,
      name: "Escalating Resilience",
      type: "Weapon",
      winrate: 49.77,
      pickrate: "1.07%",
      path: "/itemicons/weapon/3000/100px-Escalating_Resilience.png",
      cost: 3000,
      purchaseStats: [
        { name: "Fire Rate", value: "+14%" },
        { name: "Weapon Damage", value: "+14%" },
      ],
      passive: {
        description:
          "Grants Bullet Resist when hitting enemy heroes with bullets.",
        effects: [
          { name: "Max Bullet Resist", value: "40%" },
          { name: "Bullet Resist per Stack", value: "2%" },
        ],
        stackDuration: 20,
      },
    },
    {
      id: 25,
      name: "Toxic Bullets",
      type: "Weapon",
      winrate: 49.61,
      pickrate: "7.58%",
      path: "/itemicons/weapon/3000/100px-Toxic_Bullets.png",
      cost: 3000,
      purchaseStats: [{ name: "Bonus Health", value: 100 }],
      passive: {
        description: "Bullets apply Bleed and Healing Reduction to enemies.",
        effects: [
          { name: "Bleed Damage", value: "5%/sec" },
          { name: "Healing Reduction", value: "-65%" },
        ],
        duration: 3,
        buildupPerShot: 10.3,
      },
    },
    {
      id: 26,
      name: "Improved Burst",
      type: "Spirit",
      winrate: 49.48,
      pickrate: "13.73%",
      path: "/itemicons/spirit/3000/100px-Improved_Burst.png",
      cost: 3000,
      components: ["Mystic Burst"],
      purchaseStats: [
        { name: "Spirit Shield Health", value: 100 },
        { name: "Weapon Damage", value: "+8%" },
      ],
      passive: {
        description: "Abilities deal bonus Spirit Damage on high-damage hits.",
        effects: [
          { name: "Bonus Spirit Damage", value: "+9% Max Health" },
          { name: "Condition", value: "125+ damage in a single hit" },
        ],
        immunityDuration: 5,
      },
    },
    {
      id: 27,
      name: "Rapid Recharge",
      type: "Spirit",
      winrate: 49.28,
      pickrate: "10.20%",
      path: "/itemicons/spirit/3000/100px-Rapid_Recharge.png",
      cost: 3500,
      components: ["Extra Charge"],
      purchaseStats: [
        { name: "Bonus Ability Charges", value: 2 },
        { name: "Time Between Charges", value: "-55%" },
        { name: "Charged Ability Cooldown Reduction", value: "25%" },
        { name: "Weapon Damage", value: "+12%" },
      ],
    },
    {
      id: 28,
      name: "Fortitude",
      type: "Vitality",
      winrate: 48.57,
      pickrate: "6.29%",
      path: "/itemicons/vitality/3000/100px-Fortitude.png",
      cost: 3000,
      purchaseStats: [{ name: "Bonus Health", value: 275 }],
      passive: {
        description:
          "Regen health after not taking damage for 11s and gain bonuses when above 75% health.",
        effects: [
          { name: "Max Health Regen", value: "4%" },
          { name: "Weapon Damage", value: "+25%" },
          { name: "Move Speed", value: "+2m/s" },
        ],
      },
    },
    {
      id: 29,
      name: "Ethereal Shift",
      type: "Spirit",
      winrate: 48.29,
      pickrate: "1.49%",
      path: "/itemicons/spirit/3000/100px-Ethereal_Shift.png",
      cost: 3000,
      active: {
        cooldown: 30,
        description: "Enter void state, becoming untargetable and invincible.",
        effects: [
          { name: "Duration", value: 3.5 },
          { name: "Spirit Power", value: "+14" },
        ],
        bonusDuration: 10,
      },
    },
    {
      id: 30,
      name: "Debuff Remover",
      type: "Vitality",
      winrate: 48.03,
      pickrate: "0.83%",
      path: "/itemicons/vitality/3000/100px-Debuff_Remover.png",
      cost: 4250,
      components: ["Debuff Reducer"],
      purchaseStats: [
        { name: "Bonus Health", value: 125 },
        { name: "Weapon Damage", value: "+20%" },
      ],
      passive: {
        description: "Reduces duration of negative effects.",
        effects: [{ name: "Debuff Resist", value: "35%" }],
      },
      active: {
        cooldown: 45,
        description: "Purge negative effects and gain movement speed.",
        effects: [{ name: "Movement Speed", value: "+3m/s" }],
        duration: 3,
      },
    },
    {
      id: 31,
      name: "Knockdown",
      type: "Spirit",
      winrate: 47.59,
      pickrate: "1.22%",
      path: "/itemicons/spirit/3000/100px-Knockdown.png",
      cost: 3500,
      purchaseStats: [
        { name: "Stamina", value: 1 },
        { name: "Spirit Shield Health", value: 200 },
        { name: "Spirit Power", value: 6 },
      ],
      active: {
        cooldown: 45,
        description: "Knock down and stun target after a delay.",
        effects: [
          { name: "Stun Duration", value: 0.9 },
          { name: "Cast Range", value: 45 },
        ],
        delay: 2,
      },
    },
    {
      id: 32,
      name: "Alchemical Fire",
      type: "Weapon",
      winrate: 46.76,
      pickrate: "0.49%",
      path: "/itemicons/weapon/3000/100px-Alchemical_Fire.png",
      cost: 3000,
      purchaseStats: [
        { name: "Bullet Shield Health", value: 225 },
        { name: "Weapon Damage", value: "+14%" },
        { name: "Spirit Power", value: 11 },
      ],
      active: {
        cooldown: 26,
        description:
          "Throw an explosive flask, damaging enemies and increasing team damage.",
        effects: [
          { name: "DPS", value: 46 },
          { name: "Conditional Weapon Damage", value: "+50%" },
          { name: "Max DPS", value: 97 },
          { name: "Radius", value: 10 },
        ],
        duration: 5,
      },
    },
    {
      id: 33,
      name: "Sharpshooter",
      type: "Weapon",
      winrate: 46.53,
      pickrate: "6.85%",
      path: "/itemicons/weapon/3000/100px-Sharpshooter.png",
      cost: 4250,
      components: ["Long Range"],
      purchaseStats: [
        { name: "Ammo", value: "+20%" },
        { name: "Weapon Fall-off Range", value: "+15%" },
        { name: "Weapon Zoom", value: "+30%" },
        { name: "Bullet Shield Health", value: 200 },
      ],
      passive: {
        description: "Deal additional damage at long range.",
        effects: [
          { name: "Weapon Damage", value: "+70%" },
          { name: "Minimum Distance", value: 15 },
        ],
      },
    },
    {
      id: 34,
      name: "Silence Glyph",
      type: "Spirit",
      winrate: 46.51,
      pickrate: "0.62%",
      path: "/itemicons/spirit/3000/100px-Silence_Glyph.png",
      cost: 3000,
      purchaseStats: [
        { name: "Spirit Shield Health", value: 150 },
        { name: "Sprint Speed", value: "+1m/s" },
      ],
      active: {
        cooldown: 32,
        description: "Fire a projectile, silencing and damaging the target.",
        effects: [
          { name: "Cast Range", value: 25 },
          { name: "Duration", value: 3 },
          { name: "Spirit Damage", value: 100 + "(1.00 * SpiritPower)" },
        ],
      },
    },
    {
      id: 35,
      name: "Metal Skin",
      type: "Vitality",
      winrate: 46.04,
      pickrate: "0.96%",
      path: "/itemicons/vitality/3000/100px-Metal_Skin.png",
      cost: 3000,
      purchaseStats: [
        { name: "Bonus Health", value: 125 },
        { name: "Spirit Power", value: 8 },
        { name: "Fire Rate", value: "+8%" },
      ],
      active: {
        cooldown: 22,
        description: "Become immune to bullets and melee attacks.",
        effects: [{ name: "Immunity Duration", value: 3.5 }],
      },
    },
  ],
  "Tier 4": [
    {
      id: 1,
      name: "Ricochet",
      type: "Weapon",
      winrate: 57.58,
      pickrate: "3.72%",
      path: "/itemicons/weapon/6300/100px-Ricochet.png",
      cost: 6300,
      purchaseStats: [
        { name: "Ammo", value: "+35%" },
        { name: "Fire Rate", value: "+12%" },
        { name: "Bonus Health", value: 150 },
      ],
      passive: {
        description: "Bullets ricochet to nearby enemies.",
        effects: [
          { name: "Ricochet Damage", value: "60%" },
          { name: "Ricochet Targets", value: 2 },
          { name: "Ricochet Range", value: 14 },
        ],
      },
    },
    {
      id: 2,
      name: "Siphon Bullets",
      type: "Weapon",
      winrate: 57.21,
      pickrate: "4.44%",
      path: "/itemicons/weapon/6300/100px-Siphon_Bullets.png",
      cost: 6300,
      purchaseStats: [{ name: "Weapon Damage", value: "+28%" }],
      passive: {
        description: "Bullets steal Max HP from enemies.",
        effects: [
          { name: "Max HP Steal Per Bullet", value: 45 },
          { name: "Steal Duration", value: 20 },
          { name: "Max Frequency", value: 0.8 },
        ],
      },
    },
    {
      id: 3,
      name: "Escalating Exposure",
      type: "Spirit",
      winrate: 56.74,
      pickrate: "3.11%",
      path: "/itemicons/spirit/6300/100px-Escalating_Exposure.png",
      cost: 7500,
      components: ["Mystic Vulnerability"],
      purchaseStats: [
        { name: "Spirit Resist", value: "+15%" },
        { name: "Bonus Health", value: 125 },
        { name: "Spirit Resist on Spirit Damage", value: "-15%" },
      ],
      passive: {
        description: "Dealing Spirit Damage applies Spirit Amp.",
        effects: [
          { name: "Spirit Amp per Stack", value: "+6%" },
          { name: "Duration", value: 15 },
          { name: "Max Frequency per Target", value: 0.7 },
        ],
      },
    },
    {
      id: 4,
      name: "Mystic Reverb",
      type: "Spirit",
      winrate: 56.19,
      pickrate: "1.82%",
      path: "/itemicons/spirit/6300/100px-Mystic_Reverb.png",
      cost: 6300,
      purchaseStats: [
        { name: "Spirit Lifesteal", value: "+15%" },
        { name: "Spirit Resist", value: "+15%" },
        { name: "Ability Range", value: "+15%" },
      ],
      passive: {
        cooldown: 6,
        description: "Imbue ability to slow and damage enemies in radius.",
        effects: [
          { name: "Damage", value: "+40% (Conditional)" },
          { name: "Movement Slow", value: "+50% (Conditional)" },
          { name: "Radius", value: 16 },
          { name: "Delay Duration", value: 3 },
        ],
      },
    },
    {
      id: 5,
      name: "Diviner's Kevlar",
      type: "Spirit",
      winrate: 56.16,
      pickrate: "2.33%",
      path: "/itemicons/spirit/6300/100px-Diviner's_Kevlar.png",
      cost: 6300,
      purchaseStats: [
        { name: "Spirit Lifesteal", value: "+20%" },
        { name: "Cooldown Reduction", value: "12%" },
      ],
      passive: {
        cooldown: 60,
        description:
          "Gain temporary shields and Spirit Power on ultimate cast.",
        effects: [
          { name: "Bullet Shield", value: 700 },
          { name: "Spirit Shield", value: 700 },
          { name: "Spirit Power", value: 40 },
          { name: "Duration", value: 15 },
        ],
      },
    },
    {
      id: 6,
      name: "Leech",
      type: "Vitality",
      winrate: 55.83,
      pickrate: "7.68%",
      path: "/itemicons/vitality/6300/100px-Leech.png",
      cost: 6300,
      purchaseStats: [
        { name: "Spirit Lifesteal", value: "+35%" },
        { name: "Bullet Lifesteal", value: "+35%" },
        { name: "Bonus Health", value: 175 },
        { name: "Spirit Power", value: 12 },
        { name: "Weapon Damage", value: "+15%" },
      ],
    },
    {
      id: 7,
      name: "Vampiric Burst",
      type: "Weapon",
      winrate: 55.64,
      pickrate: "0.82%",
      path: "/itemicons/weapon/6300/100px-Vampiric_Burst.png",
      cost: 6300,
      purchaseStats: [
        { name: "Move Speed", value: 2 },
        { name: "Weapon Damage", value: "+25%" },
        { name: "Bonus Health", value: 150 },
      ],
      active: {
        cooldown: 35,
        description: "Grants Lifesteal, Fire Rate, and Ammo.",
        effects: [
          { name: "Bullet Lifesteal", value: "100%" },
          { name: "Fire Rate", value: "+40%" },
          { name: "Ammo", value: "+50%" },
        ],
        duration: 4.5,
      },
    },
    {
      id: 8,
      name: "Echo Shard",
      type: "Spirit",
      winrate: 55.5,
      pickrate: "0.67%",
      path: "/itemicons/spirit/6300/100px-Echo_Shard.png",
      cost: 6300,
      purchaseStats: [
        { name: "Move Speed", value: 1 },
        { name: "Bullet Resist", value: "+16%" },
        { name: "Spirit Power", value: 12 },
      ],
      active: {
        cooldown: 21,
        description:
          "Reset cooldown of most recently used non-ultimate ability.",
      },
    },
    {
      id: 9,
      name: "Phantom Strike",
      type: "Vitality",
      winrate: 55.34,
      pickrate: "0.45%",
      path: "/itemicons/vitality/6300/100px-Phantom_Strike.png",
      cost: 6300,
      purchaseStats: [
        { name: "Bullet Resist", value: "+15%" },
        { name: "Weapon Damage", value: "+30%" },
      ],
      active: {
        cooldown: 25,
        description: "Teleport to enemy, applying Disarm, Slow, and damage.",
        effects: [
          { name: "Disarm & Slow Duration", value: 3 },
          { name: "Movement Slow", value: "50% (Conditional)" },
          { name: "Damage", value: 75 },
          { name: "Cast Range", value: 25 },
        ],
      },
    },
    {
      id: 10,
      name: "Glass Cannon",
      type: "Weapon",
      winrate: 54.88,
      pickrate: "5.77%",
      path: "/itemicons/weapon/6300/100px-Glass_Cannon.png",
      cost: 6300,
      purchaseStats: [
        { name: "Weapon Damage", value: "+70%" },
        { name: "Fire Rate", value: "+10%" },
        { name: "Move Speed", value: 1 },
        { name: "Max Health", value: "-15%" },
      ],
      passive: {
        description: "Hero kills grant permanent Weapon Damage.",
        effects: [
          { name: "Weapon Damage per Kill", value: "+10%" },
          { name: "Max Stacks", value: 7 },
        ],
      },
    },
    {
      id: 11,
      name: "Refresher",
      type: "Spirit",
      winrate: 54.86,
      pickrate: "0.87%",
      path: "/itemicons/spirit/6300/100px-Refresher.png",
      cost: 6300,
      purchaseStats: [
        { name: "Spirit Resist", value: "+16%" },
        { name: "Bullet Resist", value: "+8%" },
      ],
      active: {
        cooldown: 197,
        description: "Reset ability cooldowns and restore charges.",
      },
    },
    {
      id: 12,
      name: "Frenzy",
      type: "Weapon",
      winrate: 54.65,
      pickrate: "1.69%",
      path: "/itemicons/weapon/6300/100px-Frenzy.png",
      cost: 6300,
      purchaseStats: [
        { name: "Ammo", value: 12 },
        { name: "Weapon Damage", value: "+30%" },
        { name: "Bonus Health", value: 200 },
      ],
      passive: {
        description: "Gain stat bonuses below 40% health.",
        effects: [
          { name: "Movement Speed", value: 4 },
          { name: "Fire Rate", value: "+40%" },
          { name: "Spirit Resist", value: "+45%" },
        ],
      },
    },
    {
      id: 13,
      name: "Inhibitor",
      type: "Vitality",
      winrate: 54.62,
      pickrate: "1.70%",
      path: "/itemicons/vitality/6300/100px-Inhibitor.png",
      cost: 6300,
      purchaseStats: [
        { name: "Weapon Damage", value: "+25%" },
        { name: "Bonus Health", value: 175 },
      ],
      passive: {
        cooldown: "{{{passive1_cooldown}}}",
        description: "Bullets apply Movement Slow and damage penalty.",
        effects: [
          { name: "Movement Slow", value: "25% (Conditional)" },
          { name: "Damage Penalty", value: "-35% (Conditional)" },
          { name: "Debuff Duration", value: 2.5 },
        ],
      },
    },
    {
      id: 14,
      name: "Unstoppable",
      type: "Vitality",
      winrate: 54.44,
      pickrate: "0.97%",
      path: "/itemicons/vitality/6300/100px-Unstoppable.png",
      cost: 6300,
      purchaseStats: [
        { name: "Bonus Health", value: 150 },
        { name: "Spirit Resist", value: "+15%" },
        { name: "Move Speed", value: 1 },
        { name: "Spirit Power", value: 12 },
      ],
      active: {
        cooldown: 60,
        description: "Suppress negative status effects and become immune.",
        effects: [{ name: "Duration", value: 6 }],
      },
    },
    {
      id: 15,
      name: "Colossus",
      type: "Vitality",
      winrate: 54.28,
      pickrate: "0.42%",
      path: "/itemicons/vitality/6300/100px-Colossus.png",
      cost: 6300,
      purchaseStats: [
        { name: "Bonus Health", value: 600 },
        { name: "Melee Damage", value: "+20%" },
        { name: "Weapon Damage", value: "+20%" },
      ],
      active: {
        cooldown: 45,
        description: "Gain resistances and slow enemy movement.",
        effects: [
          { name: "Bullet Resist", value: "+40% (Conditional)" },
          { name: "Spirit Resist", value: "+40% (Conditional)" },
          { name: "Movement Slow", value: "35% (Conditional)" },
          { name: "Radius", value: 10 },
          { name: "Duration", value: 7 },
        ],
      },
    },
    {
      id: 16,
      name: "Crippling Headshot",
      type: "Weapon",
      winrate: 54.16,
      pickrate: "3.39%",
      path: "/itemicons/weapon/6300/100px-Crippling_Headshot.png",
      cost: 6300,
      purchaseStats: [
        { name: "Weapon Damage", value: "+20%" },
        { name: "Bullet Lifesteal", value: "+10%" },
        { name: "Spirit Lifesteal", value: "+10%" },
      ],
      passive: {
        description: "Headshots reduce target's resistances.",
        effects: [
          { name: "Bullet Resist Reduction", value: "24%" },
          { name: "Spirit Resist Reduction", value: "24%" },
          { name: "Debuff Duration", value: 4 },
        ],
      },
    },
    {
      id: 17,
      name: "Boundless Spirit",
      type: "Spirit",
      winrate: 54.14,
      pickrate: "4.28%",
      path: "/itemicons/spirit/6300/100px-Boundless_Spirit.png",
      cost: 6300,
      components: ["Improved Spirit"],
      purchaseStats: [
        { name: "Spirit Power", value: 60 },
        { name: "Bonus Health", value: 300 },
        { name: "Health Regen", value: 15 },
        { name: "Weapon Damage", value: "+25%" },
        { name: "Sprint Speed", value: 2 },
      ],
    },
    {
      id: 18,
      name: "Soul Rebirth",
      type: "Vitality",
      winrate: 53.42,
      pickrate: "0.73%",
      path: "/itemicons/vitality/6300/100px-Soul_Rebirth.png",
      cost: 6300,
      purchaseStats: [
        { name: "Cooldown Reduction", value: "+15%" },
        { name: "Spirit Power", value: 12 },
      ],
      passive: {
        cooldown: 200,
        description: "Respawn at death location with partial health.",
        effects: [
          { name: "Rebirth Health", value: "+50%" },
          { name: "Respawn Delay", value: "4s" },
        ],
      },
    },
    {
      id: 19,
      name: "Spiritual Overflow",
      type: "Weapon",
      winrate: 52.59,
      pickrate: "2.06%",
      path: "/itemicons/weapon/6300/100px-Spiritual_Overflow.png",
      cost: 6300,
      purchaseStats: [
        { name: "Fire Rate", value: "+20%" },
        { name: "Cooldown Reduction", value: "+15%" },
        { name: "Spirit Lifesteal", value: "+10%" },
      ],
      passive: {
        description: "Gain bonus Spirit Power by charging up with bullets.",
        effects: [
          { name: "Spirit Power", value: "+45" },
          { name: "Buildup Per Shot", value: 0.8 },
          { name: "Duration", value: 18 },
        ],
      },
    },
    {
      id: 20,
      name: "Magic Carpet",
      type: "Spirit",
      winrate: 51.49,
      pickrate: "0.08%",
      path: "/itemicons/spirit/6300/100px-Magic_Carpet.png",
      cost: 6300,
      purchaseStats: [
        { name: "Ability Duration", value: "+10%" },
        { name: "Spirit Power", value: 15 },
        { name: "Bonus Health", value: 150 },
      ],
      active: {
        cooldown: 30,
        description: "Summon Magic Carpet for shields and flight.",
        effects: [
          { name: "Bullet & Spirit Shield Health", value: 400 },
          { name: "Duration", value: 7.7 },
          { name: "Summon Duration", value: 1.3 },
          { name: "Bonus Fly Speed", value: 6 },
          { name: "Shield Duration", value: "17.6s" },
        ],
      },
    },
    {
      id: 21,
      name: "Shadow Weave",
      type: "Vitality",
      winrate: 51.4,
      pickrate: "0.09%",
      path: "/itemicons/vitality/6300/100px-Shadow_Weave.png",
      cost: 6300,
      purchaseStats: [
        { name: "Health Regen", value: 15 },
        { name: "Spirit Shield Health", value: 300 },
        { name: "Ammo", value: "+30%" },
      ],
      active: {
        cooldown: 45,
        description:
          "Become Stealthed and ambush with bonus Fire Rate and Spirit Power.",
        effects: [
          { name: "Stealth Duration", value: 25 },
          { name: "Invis Sprint Speed", value: 2 },
          { name: "Spot Radius", value: 18 },
          { name: "Ambush Fire Rate", value: "+40% (Conditional)" },
          { name: "Ambush Spirit Power", value: "+55 (Conditional)" },
          { name: "Ambush Duration", value: "8s" },
        ],
      },
    },
    {
      id: 22,
      name: "Silencer",
      type: "Weapon",
      winrate: 51.2,
      pickrate: "0.79%",
      path: "/itemicons/weapon/6300/100px-Silencer.png",
      cost: 6300,
      components: ["Slowing Bullets"],
      purchaseStats: [
        { name: "Bullet Slow Proc", value: "+25%" },
        { name: "Weapon Damage", value: "+20%" },
      ],
      active: {
        cooldown: 34,
        description: "Apply Silence to targets with bullets.",
        effects: [
          { name: "Silence Duration", value: "2s" },
          { name: "Silenced Status Effect", value: "True" },
        ],
      },
    },
    {
      id: 23,
      name: "Curse",
      type: "Spirit",
      winrate: 47.47,
      pickrate: "0.63%",
      path: "/itemicons/spirit/6300/100px-Curse.png",
      cost: 6300,
      purchaseStats: [
        { name: "Weapon Damage", value: "+20%" },
        { name: "Spirit Power", value: 8 },
      ],
      active: {
        cooldown: 50,
        description: "Curse an enemy, interrupting and silencing.",
        effects: [
          { name: "Status Duration", value: "3.25s" },
          { name: "Cast Range", value: 20 },
        ],
      },
    },
    {
      id: 24,
      name: "Lucky Shot",
      type: "Weapon",
      winrate: 55.19,
      pickrate: "5.83%",
      path: "/itemicons/weapon/6300/100px-Lucky_Shot.png",
      cost: 6300,
      purchaseStats: [{ name: "Ammo", value: "+30%" }],
      passive: {
        description: "Bullets have a chance to deal bonus damage and Slow.",
        effects: [
          { name: "Proc Chance", value: 35 },
          { name: "Bonus Damage", value: "+90%" },
          { name: "Movement Slow", value: "30% (Conditional)" },
          { name: "Slow Duration", value: "1.5s" },
        ],
      },
    },
  ],
};
Object.keys(itemDataWithPaths).forEach((tier) => {
  itemDataWithPaths[tier].forEach((item) => {
    item.winrate = parseFloat(item.winrate);
  });
});
Object.keys(itemDataWithPaths).forEach((tier) => {
  itemDataWithPaths[tier].forEach((item) => {
    item.pickrate = parseFloat(item.pickrate.replace("%", ""));
  });
});

export default itemDataWithPaths;

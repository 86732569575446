// UserContext.js
import { createContext, useState, useEffect } from "react";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch("https://api.dlg.gg/api/current-user", {
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();

          // Format user data before setting it in the context
          const formattedUser = {
            username: data.displayName,
            steamId: data.id,
            // Add other necessary properties here
          };
          setUser(formattedUser);
        } else {
          console.error("Response not OK", response.status);
        }
      } catch (error) {
        console.error("Error fetching user data");
      }
    };

    fetchUserData();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };

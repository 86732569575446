import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import "./HeroWinrateChart.css";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <img src={payload[0].payload.icon} width={40} height={40} />

        <p className="label">{payload[0].payload.name}</p>
        <p className="pickrate">Pick Rate: {payload[0].payload.pickrate}%</p>
        <p className="winrate">Win Rate: {payload[0].payload.winrate}%</p>
      </div>
    );
  }

  return null;
};

const HeroesChart = ({ data }) => {
  const WINRATE_COLORS = [
    "#FF7A7A",
    "#FFC07A",
    "#FFFF7A",
    "#8BC34A",
    "#34C759",
  ];

  const getWinrateColor = (winrate) => {
    if (winrate < 45) {
      return WINRATE_COLORS[0]; // Very Low win rate
    } else if (winrate < 48) {
      return WINRATE_COLORS[1]; // Low win rate
    } else if (winrate < 52) {
      return WINRATE_COLORS[2]; // Medium win rate
    } else if (winrate < 55) {
      return WINRATE_COLORS[3]; // High win rate
    } else {
      return WINRATE_COLORS[4]; // Very High win rate
    }
  };
  return (
    <div>
      <ResponsiveContainer width="95%" height={600}>
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          style={{
            color: "white", // Chart text color
          }}
        >
          <CartesianGrid
            stroke="#FFFFFF" // Grid line color
            strokeOpacity={0.7}
          />
          <XAxis
            type="number"
            dataKey="pickrate"
            name="Pick Rate"
            unit="%"
            axisLine={{ stroke: "#FFFFFF" }} // X-axis line color
            tickLine={{ stroke: "#FFFFFF" }} // X-axis tick color
            tick={{ fontSize: 14, fill: "#FFFFFF" }} // X-axis tick label color
          />
          <YAxis
            type="number"
            dataKey="winrate"
            name="Win Rate"
            unit="%"
            axisLine={{ stroke: "#FFFFFF" }} // Y-axis line color
            tickLine={{ stroke: "#FFFFFF" }} // Y-axis tick color
            tick={{ fontSize: 14, fill: "#FFFFFF" }} // Y-axis tick label color
          />

          <ZAxis dataKey="name" range={[0, 100]} />
          <Tooltip content={<CustomTooltip />} />
          <Scatter
            data={data}
            shape="circle"
            dataKey="pickrate"
            valueKey="winrate"
          >
            {data.map((hero, index) => {
              return <Cell key={index} fill={getWinrateColor(hero.winrate)} />;
            })}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HeroesChart;

import React, { useState } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import Switch from "@mui/material/Switch";
import "../pages/HeroDetailPage.css";
import CustomTooltip from "./CustomTooltip";
const BaseStats = ({ stats }) => {
  const [showChart, setShowChart] = useState(true);

  // Convert string values to numbers
  const maxHealth = parseFloat(stats.maxHealth);
  const moveSpeed = parseFloat(stats.moveSpeed.split(" ")[0]); // Remove "m/s"
  const lightMeleeDamage = parseFloat(stats.lightMeleeDamage);
  const heavyMeleeDamage = parseFloat(stats.heavyMeleeDamage);

  const maxMaxHealth = 1000;
  const maxMoveSpeed = 10;
  const maxLightMeleeDamage = 150;
  const maxHeavyMeleeDamage = 250;

  const data = [
    {
      subject: "Max Health",
      Value: (maxHealth / maxMaxHealth) * 100,
    },
    {
      subject: "Move Speed",
      Value: (moveSpeed / maxMoveSpeed) * 100,
    },
    {
      subject: "Light Melee Damage",
      Value: (lightMeleeDamage / maxLightMeleeDamage) * 100,
    },
    {
      subject: "Heavy Melee Damage",
      Value: (heavyMeleeDamage / maxHeavyMeleeDamage) * 100,
    },
  ];

  const handleToggle = () => {
    setShowChart(!showChart);
  };

  return (
    <div className="base-stats">
      <div className="title-container">
        <h2 className="base-title">Base Stats</h2>
        <div className="toggle-switch-container">
          <span className="toggle-switch">Toggle Chart</span>
          <Switch checked={showChart} onChange={handleToggle} />
        </div>
      </div>
      {showChart ? (
        <div className="stats-chart-wrapper">
          <ResponsiveContainer width="100%" height={400}>
            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" tick={{ fill: "#FFFFFF" }} />
              <Radar
                name="Base Stats"
                dataKey="Value"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
              />
              <Tooltip content={<CustomTooltip />} />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className="raw-stats">
          <ul className="base-stats-list">
            <li>
              <span>Max Health: </span>
              <span className="font-semibold">{stats.maxHealth}</span>
            </li>
            <li>
              <span>Move Speed: </span>
              <span className="font-semibold">{stats.moveSpeed}</span>
            </li>
            <li>
              <span>Light Melee Damage: </span>
              <span className="font-semibold">{stats.lightMeleeDamage}</span>
            </li>
            <li>
              <span>Heavy Melee Damage: </span>
              <span className="font-semibold">{stats.heavyMeleeDamage}</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default BaseStats;

import React, { useState } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import Switch from "@mui/material/Switch";
import "../pages/HeroDetailPage.css";

const WeaponStats = ({ stats }) => {
  const [showChart, setShowChart] = useState(true);

  // Convert string values to numbers
  const clipSize = parseFloat(stats.clipSize);
  const bulletDamage = parseFloat(stats.bulletDamage);
  const fireRate = parseFloat(stats.fireRate.split(" ")[0]); // Remove "rounds/s"
  const DPS = parseFloat(stats.DPS);

  const maxClipSize = 20;
  const maxBulletDamage = 10;
  const maxFireRate = 5;
  const maxDPS = 150;

  const data = [
    {
      subject: "Clip Size",
      Value: (parseFloat(stats.clipSize) / maxClipSize) * 100,
    },
    {
      subject: "Bullet Damage",
      Value: (parseFloat(stats.bulletDamage) / maxBulletDamage) * 100,
    },
    {
      subject: "Fire Rate (rounds/s)",
      Value: (parseFloat(stats.fireRate.split(" ")[0]) / maxFireRate) * 100,
    },
    {
      subject: "DPS",
      Value: (parseFloat(stats.DPS) / maxDPS) * 100,
    },
  ];

  const handleToggle = () => {
    setShowChart(!showChart);
  };

  return (
    <div className="weapon-stats">
      <div className="title-container">
        <h2 className="weapon-title">Weapon Stats</h2>
        <div className="toggle-switch-container">
          <span className="toggle-switch">Toggle Chart</span>
          <Switch checked={showChart} onChange={handleToggle} />
        </div>
      </div>
      {showChart ? (
        <div className="stats-chart-wrapper">
          <ResponsiveContainer
            width="99%"
            height={400}
            // style={{ position: "relative", right: "26px" }}
          >
            <RadarChart cy="50%" data={data}>
              <PolarGrid />
              <PolarAngleAxis
                dataKey="subject"
                tick={{ fill: "#FFFFFF" }} // Change tick text color to white
              />
              <Radar
                style={{ fontSize: "50px" }}
                name="Weapon Stats"
                dataKey="Value"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
              />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className="raw-stats">
          <ul>
            <li>
              <span>Clip Size: </span>
              <span className="font-semibold">{stats.clipSize}</span>
            </li>
            <li>
              <span>Bullet Damage: </span>
              <span className="font-semibold">{stats.bulletDamage}</span>
            </li>
            <li>
              <span>Fire Rate: </span>
              <span className="font-semibold">{stats.fireRate}</span>
            </li>
            <li>
              <span>DPS: </span>
              <span className="font-semibold">{stats.DPS}</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default WeaponStats;

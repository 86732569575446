import React, { forwardRef, useState, useImperativeHandle } from "react";
import Tooltip from "@mui/material/Tooltip";
import AbilityTooltip from "./AbilityTooltip";
import AbilityIcon from "../svg/ability.png";

const SkillBuildComponent = forwardRef((props, ref) => {
  const {
    abilities,
    heroName,
    skillBuildData, // Accept pre-populated skillBuildData
  } = props;
  const [skillBuild, setSkillBuild] = useState(
    skillBuildData?.skillBuild || abilities?.map(() => Array(16).fill(null))
  );
  const [abilityLevels, setAbilityLevels] = useState(
    skillBuildData?.abilityLevels || abilities?.map(() => 1)
  );
  const [selectedInColumn, setSelectedInColumn] = useState(
    skillBuildData?.selectedInColumn || Array(16).fill(null)
  );

  // Helper function to map the level to the desired display value
  const displayLevel = (abilityIndex, levelIndex) => {
    const assignedLevels = skillBuild[abilityIndex].filter(
      (level) => level !== null
    );
    const positionInAssignedLevels = assignedLevels.indexOf(
      skillBuild[abilityIndex][levelIndex]
    );

    if (positionInAssignedLevels === 0) return "";
    if (positionInAssignedLevels === 1) return "1";
    if (positionInAssignedLevels === 2) return "2";
    if (positionInAssignedLevels === 3) return "5";
    return ""; // Return empty string for other positions or unassigned levels
  };

  const handleAbilityClick = (abilityIndex, levelIndex) => {
    const updatedSkillBuild = [...skillBuild];
    const currentAbilityLevel = abilityLevels[abilityIndex];

    // Deselect any ability currently selected in this column
    if (
      selectedInColumn[levelIndex] !== null &&
      selectedInColumn[levelIndex] !== abilityIndex
    ) {
      const previousAbilityIndex = selectedInColumn[levelIndex];
      updatedSkillBuild[previousAbilityIndex][levelIndex] = null;
      adjustLevels(previousAbilityIndex, updatedSkillBuild);
    }

    // Deselect the ability if it's already selected
    if (updatedSkillBuild[abilityIndex][levelIndex] !== null) {
      // Reset subsequent levels
      for (let i = levelIndex; i < 16; i++) {
        updatedSkillBuild[abilityIndex][i] = null;
      }

      const updatedAbilityLevels = [...abilityLevels];
      updatedAbilityLevels[abilityIndex] = Math.max(
        1,
        abilityLevels[abilityIndex] - 1
      );

      setSkillBuild(updatedSkillBuild);
      setAbilityLevels(updatedAbilityLevels);

      // Clear the selected ability for this column
      const updatedSelectedInColumn = [...selectedInColumn];
      updatedSelectedInColumn[levelIndex] = null;
      setSelectedInColumn(updatedSelectedInColumn);
    } else {
      // Select the ability
      updatedSkillBuild[abilityIndex][levelIndex] = currentAbilityLevel;

      const updatedAbilityLevels = [...abilityLevels];
      updatedAbilityLevels[abilityIndex] = Math.min(currentAbilityLevel + 1, 6);

      setSkillBuild(updatedSkillBuild);
      setAbilityLevels(updatedAbilityLevels);

      // Set the selected ability for this column
      const updatedSelectedInColumn = [...selectedInColumn];
      updatedSelectedInColumn[levelIndex] = abilityIndex;
      setSelectedInColumn(updatedSelectedInColumn);
    }
  };

  // Adjust the levels in a row to ensure no gaps after deselection/swapping
  const adjustLevels = (abilityIndex, updatedSkillBuild) => {
    const updatedAbilityLevels = [...abilityLevels];

    // Track the next available level for this row
    let nextLevel = 1;
    for (let i = 0; i < 16; i++) {
      if (updatedSkillBuild[abilityIndex][i] !== null) {
        updatedSkillBuild[abilityIndex][i] = nextLevel;
        nextLevel++;
      }
    }

    updatedAbilityLevels[abilityIndex] = nextLevel;
    setSkillBuild(updatedSkillBuild);
    setAbilityLevels(updatedAbilityLevels);
  };

  const getSkillBuildData = () => {
    return { abilityLevels, selectedInColumn, skillBuild };
  };

  useImperativeHandle(ref, () => ({ getSkillBuildData }));

  return (
    <div className="skill-build-section">
      <h3>Skill Build</h3>
      <div className="ability-grid">
        {props.abilities?.map((ability, abilityIndex) => (
          <React.Fragment key={ability.id}>
            <div className="ability-name">
              <Tooltip title={<AbilityTooltip ability={ability} />}>
                <img
                  src={`/heroes/${props.heroName
                    .toLowerCase()
                    .replace(/\s+/g, "_")}/assets/ability_${ability.name
                    .toLowerCase()
                    .replace(/\s+/g, "_")}.png`}
                  alt={ability.name}
                  className="ability-icon"
                />
              </Tooltip>
            </div>
            {skillBuild[abilityIndex].map((level, levelIndex) => (
              <div key={levelIndex} className="ability-container">
                {level ? (
                  <div
                    className="ability-content"
                    onClick={() => handleAbilityClick(abilityIndex, levelIndex)}
                  >
                    <img src={AbilityIcon} alt={ability.name} />
                    <span className="ability-level">
                      {displayLevel(abilityIndex, levelIndex)}
                    </span>
                  </div>
                ) : (
                  <button
                    onClick={() => handleAbilityClick(abilityIndex, levelIndex)}
                  >
                    +
                  </button>
                )}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
});

export default SkillBuildComponent;

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "./GuidesPage.css";
import Sidebar from "../components/Sidebar";
import LoadingSpinner from "../components/LoadingSpinner";
import Modal from "react-modal";
import { IconBxUpvote, IconBxDownvote } from "../icons";
import { UserContext } from "../UserContext";

const FeaturedGuides = ({ selectedHero, selectedCategory, searchQuery }) => {
  const [filteredGuides, setFilteredGuides] = useState([]);
  const [guides, setGuides] = useState([]);
  const [userVotes, setUserVotes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);
  const formatHeroNameIcon = (name) =>
    name.toLowerCase().replace(/\s+/g, "_").replace("&", "and");
  const steamId = user ? user.steamId : null;

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#333",
      color: "#fff",
      padding: "20px",
      border: "none",
      borderRadius: "10px",
      boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
      fontSize: "14px",
    },
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await fetch("https://api.dlg.gg/api/guides");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // console.log(data);
        data.sort((a, b) => b.voteCount - a.voteCount);
        setGuides(data);

        const storedVotes = JSON.parse(localStorage.getItem("userVotes")) || {};
        setUserVotes(storedVotes);
        setLoading(false);
      } catch (error) {
        setError("Failed to load guides");
        setLoading(false);
      }
    };

    fetchGuides();
  }, []);

  const handleVote = async (guideId, type) => {
    if (!steamId) {
      setModalIsOpen(true);
      return;
    }
    if (type !== "neutral") {
      const voteCountElement = document.querySelector(`#vote-count-${guideId}`);
      if (type === "upvote") {
        voteCountElement.classList.add("upvoted");
        voteCountElement.classList.remove("downvoted");
      } else if (type === "downvote") {
        voteCountElement.classList.add("downvoted");
        voteCountElement.classList.remove("upvoted");
      }

      setTimeout(() => {
        voteCountElement.classList.remove("upvoted", "downvoted");
      }, 500);
    }
    try {
      const existingVote = userVotes[guideId];

      if (existingVote) {
        await fetch(`https://www.api.dlg.gg/api/guides/${guideId}/unvote`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ steamId }),
        });

        const updatedGuides = guides.map((guide) =>
          guide._id === guideId
            ? {
                ...guide,
                voteCount:
                  guide.voteCount - (existingVote === "upvote" ? 1 : -1),
              }
            : guide
        );
        setGuides(updatedGuides);

        const updatedVotes = { ...userVotes };
        delete updatedVotes[guideId];
        setUserVotes(updatedVotes);
        localStorage.setItem("userVotes", JSON.stringify(updatedVotes));

        if (type !== "neutral") {
          const response = await fetch(
            `https://api.dlg.gg/api/guides/${guideId}/vote`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ type, steamId }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to submit vote.");
          }

          const updatedVoteCount = await response.json();

          const updatedGuides2 = guides.map((guide) =>
            guide._id === guideId
              ? {
                  ...guide,
                  voteCount: updatedVoteCount.voteCount,
                }
              : guide
          );
          setGuides(updatedGuides2);

          const updatedVotes2 = { ...userVotes, [guideId]: type };
          setUserVotes(updatedVotes2);
          localStorage.setItem("userVotes", JSON.stringify(updatedVotes2));
        }
      } else {
        if (type !== "neutral") {
          const response = await fetch(
            `https://api.dlg.gg/api/guides/${guideId}/vote`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ type, steamId }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to submit vote.");
          }

          const updatedVoteCount = await response.json();

          const updatedGuides = guides.map((guide) =>
            guide._id === guideId
              ? {
                  ...guide,
                  voteCount: updatedVoteCount.voteCount,
                }
              : guide
          );
          setGuides(updatedGuides);

          const updatedVotes = { ...userVotes, [guideId]: type };
          setUserVotes(updatedVotes);
          localStorage.setItem("userVotes", JSON.stringify(updatedVotes));
        }
      }
    } catch (error) {
      console.error("Error submitting vote:", error);
    }
  };

  const filteredGuideList = guides.filter(
    (guide) =>
      (selectedHero ? guide.hero === selectedHero : true) &&
      (selectedCategory ? guide.category === selectedCategory : true) &&
      (searchQuery
        ? guide.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          guide.hero.toLowerCase().includes(searchQuery.toLowerCase())
        : true)
  );

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="featured-guides">
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Login Required"
        >
          <h2>You must be logged in to vote</h2>
          <button onClick={closeModal}>Close</button>
        </Modal>
      </div>
      <h2>Guides</h2>
      <ul>
        {filteredGuideList.map((guide) => (
          <li key={guide._id} className="guide-item">
            <div className="vote-container">
              <button
                className={`vote-button upvote ${
                  userVotes[guide._id] === "upvote" ? "voted" : ""
                }`}
                onClick={() => handleVote(guide._id, "upvote")}
              >
                <IconBxUpvote />
              </button>
              <span
                id={`vote-count-${guide._id}`}
                className={`vote-count ${
                  userVotes[guide._id] === "upvote" ? "upvoted" : ""
                } ${userVotes[guide._id] === "downvote" ? "downvoted" : ""}`}
                onClick={() => handleVote(guide._id, "neutral")}
                style={{ cursor: "pointer" }}
              >
                {guide.voteCount || 0}
              </span>
              <button
                className={`vote-button downvote ${
                  userVotes[guide._id] === "downvote" ? "voted" : ""
                }`}
                onClick={() => handleVote(guide._id, "downvote")}
              >
                <IconBxDownvote />
              </button>
            </div>

            <div className="guide-info">
              <Link to={`/guides/${guide.slug}`} className="guide-link">
                <img
                  src={`/charicon/${formatHeroNameIcon(guide.hero)}.webp`}
                  alt={guide.hero}
                  className="hero-icon"
                />
              </Link>
              <div className="guide-links">
                <Link to={`/guides/${guide.slug}`} className="guide-link">
                  <h3>{guide.title}</h3>
                </Link>
                {guide.username && (
                  <div className="guide-username">
                    <Link to={`/profile/${guide.username}`}>
                      {guide.username}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const GuidesPage = () => {
  const [selectedHero, setSelectedHero] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleHeroChange = (hero) => {
    setSelectedHero(hero);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="guides-page">
      <Sidebar
        onHeroChange={handleHeroChange}
        onCategoryChange={handleCategoryChange}
      />
      <div className="content">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search guides"
          style={{
            width: "300px",
            padding: "10px",
            backgroundColor: "#31313c",
            color: "white",
            border: "1px solid #ccc",
            borderRadius: "5px",
            fontSize: "1em",
          }}
        />
        <FeaturedGuides
          selectedHero={selectedHero}
          selectedCategory={selectedCategory}
          searchQuery={searchQuery}
        />
      </div>
    </div>
  );
};

export default GuidesPage;

import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "./CreateGuidePage.css";
import AddItemButton from "../components/AddItemButton";
import ItemSelection from "../components/ItemSelection"; // Import the new ItemSelection component
// #1 import quill-image-uploader
import ImageUploader from "quill-image-uploader";
import Quill from "quill"; // Import Quill
import AuthorProfiles from "../components/AuthorProfiles";
import { UserContext } from "../UserContext";
import CollapsibleSection from "../components/CollapsibleSection";
import itemDataWithPaths from "../components/itemDataWithPaths";
import Tooltip from "@mui/material/Tooltip";
import ItemTooltip from "../components/ItemTooltip";
import SkillBuildComponent from "../components/SkillBuildComponent";
import heroData from "../components/heroData";
// #2 register module
Quill.register("modules/imageUploader", ImageUploader);
const CreateGuidePage = () => {
  const { user } = useContext(UserContext);
  const [isEarlyGameVisible, setIsEarlyGameVisible] = useState(false);
  const [isMidGameVisible, setIsMidGameVisible] = useState(false);
  const [isLateGameVisible, setIsLateGameVisible] = useState(false);
  const [earlyGameSelectedItems, setEarlyGameSelectedItems] = useState([]);
  const [midGameSelectedItems, setMidGameSelectedItems] = useState([]);
  const [lateGameSelectedItems, setLateGameSelectedItems] = useState([]);
  const [skillBuildData, setSkillBuildData] = useState({});
  const handleSkillBuildSubmit = (data) => {
    setSkillBuildData(data);
  };
  const skillBuildRef = useRef(null);

  const [isItemBuildSectionVisible, setIsItemBuildSectionVisible] =
    useState(true);
  const [itemBuildTriangleClass, setItemBuildTriangleClass] =
    useState("triangle");
  const [heroTemp, setHeroTemp] = useState("Abrams");
  const [title, setTitle] = useState("");
  const [hero, setHero] = useState("Abrams");
  const [content, setContent] = useState("");
  const [error, setError] = useState(null); // State to capture errors
  const [username, setUsername] = useState(""); // State to capture the username
  const [category, setCategory] = useState("Hero"); // New state for category
  const decodedHeroName = heroTemp.replace("%20", "_").replace("&", "_");

  const [selectedItems, setSelectedItems] = useState([]); // Track selected items for item build
  const navigate = useNavigate();
  const [isItemBuildVisible, setIsItemBuildVisible] = useState(true);
  const [twitchProfile, setTwitchProfile] = useState("");
  const [youtubeProfile, setYoutubeProfile] = useState("");
  const [twitterProfile, setTwitterProfile] = useState("");
  const [isAuthorProfilesVisible, setIsAuthorProfilesVisible] = useState(true);
  const [authorProfilesTriangleClass, setAuthorProfilesTriangleClass] =
    useState("triangle");
  const [chapters, setChapters] = useState([{ title: "", content: "" }]);
  const toggleAuthorProfilesVisibility = () => {
    setIsAuthorProfilesVisible(!isAuthorProfilesVisible);
    setAuthorProfilesTriangleClass(
      authorProfilesTriangleClass === "triangle"
        ? "triangle triangle-up"
        : "triangle"
    );
  };
  const [isGeneralInfoVisible, setIsGeneralInfoVisible] = useState(true);
  const [isChaptersVisible, setIsChaptersVisible] = useState(true);

  const generalInfoTriangleClass = isGeneralInfoVisible
    ? "triangle triangle-up"
    : "triangle";
  const chaptersTriangleClass = isChaptersVisible
    ? "triangle triangle-up"
    : "triangle";
  const triangleClass = isItemBuildVisible
    ? "triangle triangle-up"
    : "triangle";

  const toggleGeneralInfoVisibility = () =>
    setIsGeneralInfoVisible(!isGeneralInfoVisible);
  const toggleChaptersVisibility = () =>
    setIsChaptersVisible(!isChaptersVisible);
  const [isEarlyGameModalOpen, setIsEarlyGameModalOpen] = useState(false);
  const [isMidGameModalOpen, setIsMidGameModalOpen] = useState(false);
  const [isLateGameModalOpen, setIsLateGameModalOpen] = useState(false);
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);

  const getItemData = (path) => {
    const flattenedData = Object.values(itemDataWithPaths).flat();
    return flattenedData.find((item) => item.path === path);
  };
  const getItemName = (path) => {
    const item = getItemData(path);
    return item?.name || "Unknown Item";
  };

  const getItemCost = (path) => {
    const item = getItemData(path);
    return item?.cost || 0;
  };

  const getItemBackgroundColor = (path) => {
    const item = getItemData(path);
    return item?.type === "Spirit"
      ? "#dd9cff"
      : item?.type === "Vitality"
      ? "#87c81d"
      : "#fbab4d";
  };

  const getItemCostBackgroundColor = (path) => {
    const item = getItemData(path);
    return item?.type === "Spirit"
      ? "#8b55b4"
      : item?.type === "Vitality"
      ? "#659819"
      : "#ca7a03";
  };
  const getItemPurchaseStats = (item) => {
    const itemData = getItemData(item);
    return itemData.purchaseStats;
  };
  const getItemPassiveComponent = (item) => {
    const itemData = getItemData(item);
    return itemData.passive;
  };
  const getItemActiveComponent = (item) => {
    const itemData = getItemData(item);
    return itemData.active;
  };
  const getItemComponent = (item) => {
    const itemData = getItemData(item);
    return itemData.componentOf;
  };
  const imageHandler = (image) => {
    const quill = ReactQuill.Quill;
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", image);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch("https://api.dlg.gg/api/upload-image", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        imageHandler(data.imageUrl);
      } else {
        console.error("Failed to upload image");
      }
    } catch (err) {
      console.error("Fetch error:", err);
    }
  };
  const handleItemModalOpen = () => {
    setIsItemModalOpen(true);
  };

  const handleItemModalClose = () => {
    setIsItemModalOpen(false);
  };
  const currentHeroData = heroData[hero.replace(" ", "_")];
  if (!currentHeroData) {
    return <div>Hero not found</div>;
  }

  const heroes = [
    "Abrams",
    "Bebop",
    "Dynamo",
    "Grey Talon",
    "Haze",
    "Infernus",
    "Ivy",
    "Kelvin",
    "Lady Geist",
    "Lash",
    "McGinnis",
    "Mo & Krill",
    "Paradox",
    "Pocket",
    "Seven",
    "Shiv",
    "Vindicta",
    "Viscous",
    "Warden",
    "Wraith",
    "Yamato",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const skillBuildData = skillBuildRef.current.getSkillBuildData();

    if (!user) {
      setError(
        "Warning: You are not signed in. Your progress will be lost if you don't sign in."
      );
      return;
    }
    const newGuide = {
      title,
      hero,
      username: user.username,
      category,
      items: {
        earlyGame: earlyGameSelectedItems,
        midGame: midGameSelectedItems,
        lateGame: lateGameSelectedItems,
      },
      authorSteamId: user.steamId,
      chapters,
      socialLinks: {
        twitch: twitchProfile || "",
        youtube: youtubeProfile || "",
        twitter: twitterProfile || "",
      },
      skillBuild: skillBuildData,
    };
    try {
      const response = await fetch("https://api.dlg.gg/api/guides", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newGuide),
      });

      if (response.ok) {
        navigate("/guides");
      } else {
        const data = await response.json();
        setError(data.message || "An error occurred while saving the guide.");
      }
    } catch (err) {
      console.error("Fetch error:", err);
      setError("An error occurred while saving the guide.");
    }
  };

  const toggleItemBuildVisibility = () => {
    setIsItemBuildSectionVisible(!isItemBuildSectionVisible);
    setItemBuildTriangleClass(
      itemBuildTriangleClass === "triangle"
        ? "triangle triangle-up"
        : "triangle"
    );
  };
  return (
    <>
      <div className="create-guide-page">
        <h1>Create a New Guide</h1>
        <div className="main-content">
          {error && <p className="error-message">{error}</p>}

          <form onSubmit={handleSubmit}>
            <CollapsibleSection
              className="gap-bottom"
              title="Let's start off with the basics."
              isOpen={true}
            >
              <div className="collapse-sections">
                <div className="form-section">
                  <label
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                    htmlFor="title"
                  >
                    Guide Title:
                  </label>
                  <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>

                <div className="form-section">
                  <label
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                    htmlFor="hero"
                  >
                    Hero:
                  </label>
                  <select
                    id="hero"
                    value={hero}
                    onChange={(e) => setHero(e.target.value)}
                    required
                  >
                    <option value="">Select a Hero</option>
                    {heroes.map((heroName, index) => (
                      <option key={index} value={heroName}>
                        {heroName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-section">
                  <label
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                    htmlFor="category"
                  >
                    Category:
                  </label>
                  <select
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    required
                  >
                    <option value="Hero">Hero</option>
                    <option value="General">General</option>
                  </select>
                </div>
                <div className="form-section">
                  <label
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                    htmlFor="Socials"
                  >
                    Socials:
                  </label>
                  <input
                    type="text"
                    value={twitchProfile}
                    onChange={(e) => setTwitchProfile(e.target.value)}
                    placeholder="Twitch Profile"
                  />
                  <input
                    type="text"
                    value={youtubeProfile}
                    onChange={(e) => setYoutubeProfile(e.target.value)}
                    placeholder="YouTube Profile"
                  />
                  <input
                    type="text"
                    value={twitterProfile}
                    onChange={(e) => setTwitterProfile(e.target.value)}
                    placeholder="Twitter Profile"
                  />
                </div>
              </div>
            </CollapsibleSection>

            <CollapsibleSection
              className="gap-bottom"
              title="What order are you levelling your skills?"
              isOpen={false}
            >
              <div className="collapse-sections">
                <SkillBuildComponent
                  abilities={currentHeroData.abilities}
                  heroName={hero}
                  ref={skillBuildRef} // Use a ref to retrieve data
                />
              </div>
            </CollapsibleSection>

            <CollapsibleSection
              className="gap-bottom-border"
              title="What items will you be building, and when?"
            >
              <div>
                <h3>Early Game</h3>
                <div>
                  <AddItemButton
                    onClick={() => setIsEarlyGameModalOpen(true)}
                  />
                  {isEarlyGameModalOpen && (
                    <div className="modal-overlay">
                      <div className="modal-content">
                        <h2>Early Game</h2>
                        <ItemSelection
                          selectedItems={earlyGameSelectedItems}
                          setSelectedItems={setEarlyGameSelectedItems}
                          gamePhase="Early Game"
                        />
                        <button onClick={() => setIsEarlyGameModalOpen(false)}>
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                  <ul className="items-display-grid-create">
                    {earlyGameSelectedItems.map((item, index) => (
                      <Tooltip title={<ItemTooltip item={item} />}>
                        <li
                          key={index}
                          className="item-icon-wrapper-preview"
                          style={{
                            backgroundColor: getItemBackgroundColor(item),
                          }}
                        >
                          <div
                            className="item-cost"
                            style={{
                              backgroundColor: getItemCostBackgroundColor(item),
                            }}
                          >
                            <img
                              src={`/20px-Souls_icon.png`}
                              alt={item}
                              className="souls"
                            />
                            {getItemCost(item)}
                          </div>

                          <img src={item} alt={item} className="item-icon" />
                          <div className="item-name">{getItemName(item)}</div>
                        </li>
                      </Tooltip>
                    ))}
                  </ul>
                </div>

                <div>
                  <h3>Mid Game</h3>
                  <AddItemButton onClick={() => setIsMidGameModalOpen(true)} />
                  {isMidGameModalOpen && (
                    <div className="modal-overlay">
                      <div className="modal-content">
                        <h2>Mid Game</h2>
                        <ItemSelection
                          selectedItems={midGameSelectedItems}
                          setSelectedItems={setMidGameSelectedItems}
                          gamePhase="Mid Game"
                        />
                        <button onClick={() => setIsMidGameModalOpen(false)}>
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                  <ul className="items-display-grid-create">
                    {midGameSelectedItems.map((item, index) => (
                      <Tooltip title={<ItemTooltip item={item} />}>
                        <li
                          key={index}
                          className="item-icon-wrapper-preview"
                          style={{
                            backgroundColor: getItemBackgroundColor(item),
                          }}
                        >
                          <div
                            className="item-cost"
                            style={{
                              backgroundColor: getItemCostBackgroundColor(item),
                            }}
                          >
                            <img
                              src={`/20px-Souls_icon.png`}
                              alt={item}
                              className="souls"
                            />
                            {getItemCost(item)}
                          </div>

                          <img src={item} alt={item} className="item-icon" />
                          <div className="item-name">{getItemName(item)}</div>
                        </li>
                      </Tooltip>
                    ))}
                  </ul>
                </div>

                <div>
                  <h3>Late Game</h3>

                  <AddItemButton onClick={() => setIsLateGameModalOpen(true)} />
                  {isLateGameModalOpen && (
                    <div className="modal-overlay">
                      <div className="modal-content">
                        <h2>Late Game</h2>
                        <ItemSelection
                          selectedItems={lateGameSelectedItems}
                          setSelectedItems={setLateGameSelectedItems}
                          gamePhase="Late Game"
                        />
                        <button onClick={() => setIsLateGameModalOpen(false)}>
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                  <ul className="items-display-grid-create">
                    {lateGameSelectedItems.map((item, index) => (
                      <Tooltip title={<ItemTooltip item={item} />}>
                        <li
                          key={index}
                          className="item-icon-wrapper-preview"
                          style={{
                            backgroundColor: getItemBackgroundColor(item),
                          }}
                        >
                          <div
                            className="item-cost"
                            style={{
                              backgroundColor: getItemCostBackgroundColor(item),
                            }}
                          >
                            <img
                              src={`/20px-Souls_icon.png`}
                              alt={item}
                              className="souls"
                            />
                            {getItemCost(item)}
                          </div>

                          <img src={item} alt={item} className="item-icon" />
                          <div className="item-name">{getItemName(item)}</div>
                        </li>
                      </Tooltip>
                    ))}
                  </ul>
                </div>
              </div>
            </CollapsibleSection>

            <CollapsibleSection
              className="gap-bottom"
              title="Now the good stuff..."
            >
              <div>
                <label
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                  htmlFor="chapters"
                >
                  Chapters:
                </label>
                <button
                  onClick={() =>
                    setChapters([...chapters, { title: "", content: "" }])
                  }
                >
                  Add Chapter
                </button>
                {chapters.map((chapter, index) => (
                  <div key={index}>
                    <input
                      type="text"
                      value={chapter.title}
                      onChange={(e) => {
                        const updatedChapters = [...chapters];
                        updatedChapters[index].title = e.target.value;
                        setChapters(updatedChapters);
                      }}
                      placeholder="Chapter Title"
                    />
                    <ReactQuill
                      value={chapter.content}
                      onChange={(content) => {
                        const updatedChapters = [...chapters];
                        updatedChapters[index].content = content;
                        setChapters(updatedChapters);
                      }}
                      required
                      modules={CreateGuidePage.modules}
                      formats={CreateGuidePage.formats}
                    />
                    <button
                      onClick={() => {
                        const updatedChapters = [...chapters];
                        updatedChapters.splice(index, 1);
                        setChapters(updatedChapters);
                      }}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </CollapsibleSection>
            <button type="submit" className="publish-button">
              Publish Guide
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
CreateGuidePage.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    ["link"],
    ["image"],
    [{ align: [] }],
    ["clean"],
  ],
  matchVisual: false,
};

CreateGuidePage.formats = [
  "header",
  "font",
  "list",
  "bullet",
  "bold",
  "italic",
  "underline",
  "link",
  "align",
];

export default CreateGuidePage;

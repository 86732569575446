import React from "react";
import { useParams } from "react-router-dom";
import "./HeroDetailPage.css";
import WeaponStats from "../components/WeaponStats";
import BaseStats from "../components/BaseStats";
import Abilities from "../components/Abilities";
import HeroSpecificGuides from "../components/HeroSpecificGuides";
import { useState, useEffect } from "react";
import heroData from "../components/heroData.js";
import HeroesData from "../components/HeroesData.jsx";

const HeroDetailPage = () => {
  const { heroName } = useParams();
  const decodedHeroName = heroName.replace("%20", "_").replace("&", "_");
  const formattedHeroName = heroName.replace(/_/g, " ").replace(/and/g, "&");
  const formattedHeroNameLowerCase = heroName
    .replace(/_/g, "-")
    .replace(" ", "-")
    .toLowerCase();
  const heroDataArray = Object.values(HeroesData);

  const [guides, setGuides] = useState([]);

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await fetch(`https://api.dlg.gg/api/guides/`);
        const data = await response.json();
        const heroGuides = data.filter(
          (guide) => guide.hero === formattedHeroName
        );
        setGuides(heroGuides);
      } catch (error) {
        console.error(error);
      }
    };
    fetchGuides();
  }, [heroName]);

  const heroIconUrl = `/heroes/${decodedHeroName
    .toLowerCase()
    .replace(" ", "_")}/assets/hero_thumbnail.png`;
  const currentHeroData = heroData[decodedHeroName.replace(" ", "_")];
  if (!currentHeroData) {
    return <div>Hero not found</div>;
  }
  const currentHero = HeroesData["Patch 1.2"]?.find(
    (hero) => hero.name === formattedHeroName
  );

  const sortedHeroes = heroDataArray?.sort((a, b) => b.winrate - a.winrate);
  return (
    <>
      <div className="hero-detail-container">
        <div
          style={{
            backgroundImage: `radial-gradient(400px 200px at 60% 34%, rgba(6, 12, 19, 0) 0%, rgb(6, 12, 19) 100%), linear-gradient(90deg, rgb(7, 7, 32) 0%, rgba(7, 7, 32, 0.6) 100%),url(/splash/${formattedHeroNameLowerCase}.webp)`,
          }}
          className="hero-info"
        >
          <img
            src={heroIconUrl}
            alt={`${heroName} icon`}
            className="hero-icon-detail"
          />
          <h1 className="hero-name">
            {formattedHeroName.charAt(0).toUpperCase() +
              formattedHeroName.slice(1)}
          </h1>
        </div>
        <div className="hero-stats">
          {HeroesData["Patch 1.2"] &&
            HeroesData["Patch 1.2"].find(
              (hero) => hero.name === formattedHeroName
            ) && (
              <>
                <div className="stat-container">
                  <h4>Win Rate</h4>
                  <p>
                    {HeroesData["Patch 1.2"]
                      .find((hero) => hero.name === formattedHeroName)
                      .winrate?.toFixed(2)}
                    %
                  </p>
                </div>
                <div className="stat-container">
                  <h4>Pick Rate</h4>
                  <p>
                    {HeroesData["Patch 1.2"]
                      .find((hero) => hero.name === formattedHeroName)
                      .pickrate?.toFixed(2)}
                    %
                  </p>
                </div>
                <div className="stat-container">
                  <h4>Matches Played</h4>
                  <p>
                    {HeroesData["Patch 1.2"]
                      .find((hero) => hero.name === formattedHeroName)
                      .totalGames?.toLocaleString()}
                  </p>
                </div>
                <div className="stat-container">
                  <h4>Rank</h4>
                  <p>
                    {HeroesData["Patch 1.2"].findIndex(
                      (hero) => hero.name === formattedHeroName
                    ) + 1}
                    /{HeroesData["Patch 1.2"].length}
                  </p>
                </div>
              </>
            )}
          {!HeroesData["Patch 1.2"] ||
            (!HeroesData["Patch 1.2"].find(
              (hero) => hero.name === formattedHeroName
            ) && <p>No hero stats data available</p>)}
        </div>
        <div className="hero-bottom-grid">
          <div className="right-column">
            <Abilities
              abilities={currentHeroData.abilities}
              heroName={heroName}
            />
          </div>
          <div className="left-column">
            <WeaponStats stats={currentHeroData.weaponStats} />
            <BaseStats stats={currentHeroData.baseStats} />
          </div>
        </div>
      </div>
      <div className="guides-section">
        <h2>Guides for {formattedHeroName}</h2>
        <HeroSpecificGuides guides={guides} />
      </div>
    </>
  );
};

export default HeroDetailPage;

// src/components/Footer.jsx
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"; // Create this CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <a
            href="https://discord.gg/CxQC86yuhJ"
            target="_blank"
            rel="noreferrer"
          >
            Discord
          </a>
          <a
            href="https://discord.gg/CxQC86yuhJ"
            target="_blank"
            rel="noreferrer"
          >
            Get Deadlock Invite
          </a>
        </div>
        <div className="footer-copyright">
          <p>&copy; 2024 DLG.GG. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// src/components/FeaturedGuides.jsx
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IconBxsUpvote } from "../icons";
import "./FeaturedGuides.css";

const FeaturedGuides = () => {
  const [guides, setGuides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const formatHeroNameIcon = (heroName) => {
    return heroName.toLowerCase().replace(/\s+/g, "_").replace("&", "and");
  };
  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await fetch("https://api.dlg.gg/api/guides");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // Sort guides by voteCount in descending order
        data.sort((a, b) => b.voteCount - a.voteCount);
        setGuides(data.slice(0, 3)); // Fetch only the first three guides
        setLoading(false);
      } catch (error) {
        setError("Failed to load guides");
        setLoading(false);
      }
    };

    fetchGuides();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="featured-guides-homepage">
      <h2 style={{ marginTop: "0px" }}>Featured Guides</h2>
      <ul>
        {guides.map((guide) => (
          <li key={guide._id} className="guide-item">
            <Link to={`/guides/${guide.slug}`} className="guide-item-link">
              <div
                className="vote-container"
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: "5px",
                  padding: "5px",
                  width: "50px",
                  textAlign: "center",
                }}
              >
                <span style={{ fontSize: "18px", color: "#4caf50" }}>
                  <IconBxsUpvote />
                </span>
                <span
                  className="vote-count"
                  style={{ fontSize: "16px", color: "#fff" }}
                >
                  {guide.voteCount || 0}
                </span>
                <span
                  style={{
                    textTransform: "uppercase",
                    letterSpacing: "1px",
                    fontSize: "10px",
                  }}
                >
                  UPVOTES
                </span>
              </div>

              <div className="guide-info">
                <Link to={`/guides/${guide.slug}`} className="guide-link">
                  <img
                    src={`/heroes/${formatHeroNameIcon(
                      guide.hero
                    )}/assets/hero_thumbnail.png`}
                    alt={guide.hero}
                    className="hero-icon"
                  />
                </Link>
                <div className="guide-links">
                  <Link to={`/guides/${guide.slug}`} className="guide-link">
                    <h3>{guide.title}</h3>
                  </Link>
                  {guide.username && (
                    <div className="guide-username">
                      <span
                        style={{ fontSize: "14px", color: "rgb(145 174 212)" }}
                      >
                        by&nbsp;
                      </span>
                      <Link to={`/profile/${guide.username}`}>
                        {guide.username}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>

      <div className="divider"></div>
      <Link to="/guides" className="see-all-guides">
        See all Guides
      </Link>
    </div>
  );
};

export default FeaturedGuides;

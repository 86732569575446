import { useEffect, useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Header.css";
import { UserContext } from "../UserContext";
import HeroesIcon from "../svg/hero2.svg";
import BuildsIcon from "../svg/build.svg";
import GuidesIcon from "../svg/guide.svg";
import LoginIcon from "../svg/login.svg";

function Header() {
  const { user, setUser } = useContext(UserContext);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };

  // Optional: Handle user authentication status
  useEffect(() => {
    if (user) {
      console.log("User authenticated:");
    } else {
      console.log("User not authenticated");
    }
  }, [user]);

  const handleLogin = () => {
    window.location.href = "https://api.dlg.gg/api/auth/steam";
  };

  const handleLogout = () => {
    window.location.href = "https://api.dlg.gg/api/auth/logout";
  };

  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  return (
    <>
      <header className="header">
        <Link to="/" className="header-title">
          <img src="/dlg.png" alt="Deadlock Tracker" width="250" height="40" />
        </Link>
        <nav className="nav">
          <NavLink
            to="/heroes"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Heroes
          </NavLink>
          <NavLink
            to="/items"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Items
          </NavLink>
          {/* <NavLink
            to="/leaderboards"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Leaderboards
          </NavLink> */}
          <NavLink
            to="/guides"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Guides
          </NavLink>

          {user ? (
            <>
              {/* Profile page temporarily disabled */}
              {/* <NavLink
                to="/profile"
                className={({ isActive }) =>
                  isActive ? "nav-link active-link" : "nav-link"
                }
              >
                Profile
              </NavLink> */}
              <NavLink to="/" className="nav-link" onClick={handleLogout}>
                Log Out
              </NavLink>
            </>
          ) : (
            <button className="nav-link" onClick={handleLogin}>
              Login
            </button>
          )}
        </nav>

        {/* Mobile navigation */}
        <div className="side-nav-container">
          <button className="side-nav-btn" onClick={toggleSideNav}>
            <span className="menu-icon">≡</span>
          </button>
          <div
            className={`side-nav-overlay ${sideNavOpen ? "show" : ""}`}
          ></div>
          <div className={`side-nav ${sideNavOpen ? "show" : ""}`}>
            <div className="side-nav-content">
              <NavLink
                to="/heroes"
                className={({ isActive }) =>
                  isActive ? "side-nav-link active" : "side-nav-link"
                }
                onClick={toggleSideNav}
              >
                <img
                  style={{
                    marginRight: "10px",
                    width: "20px",
                    height: "20px",
                    filter:
                      "invert(42%) sepia(105%) saturate(0%) hue-rotate(87deg) brightness(1191%) contrast(119%)",
                  }}
                  src={HeroesIcon}
                  alt="Heroes Icon"
                />
                Heroes
              </NavLink>
              <NavLink
                to="/items"
                className={({ isActive }) =>
                  isActive ? "side-nav-link active" : "side-nav-link"
                }
                onClick={toggleSideNav}
              >
                <img
                  style={{
                    marginRight: "10px",
                    width: "20px",
                    height: "20px",
                    filter:
                      "invert(42%) sepia(93%) saturate(0%) hue-rotate(87deg) brightness(1191%) contrast(119%)",
                  }}
                  src={BuildsIcon}
                  alt="Builds Icon"
                />
                Items
              </NavLink>

              <NavLink
                to="/guides"
                className={({ isActive }) =>
                  isActive ? "side-nav-link active" : "side-nav-link"
                }
                onClick={toggleSideNav}
              >
                <img
                  style={{
                    marginRight: "10px",
                    width: "20px",
                    height: "20px",
                    filter:
                      "invert(42%) sepia(93%) saturate(0%) hue-rotate(87deg) brightness(1191%) contrast(119%)",
                  }}
                  src={GuidesIcon}
                  alt="Guides Icon"
                />
                Guides
              </NavLink>
              {user ? (
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "side-nav-link active" : "side-nav-link"
                  }
                  onClick={() => {
                    handleLogout();
                    toggleSideNav();
                  }}
                >
                  Log Out
                </NavLink>
              ) : (
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "side-nav-link active" : "side-nav-link"
                  }
                  onClick={() => {
                    handleLogin();
                    toggleSideNav();
                  }}
                >
                  Login
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;

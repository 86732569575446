import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./HeroTable.css";

const HeroTable = ({ heroes }) => {
  const decodedHeroNameLowerCase = (heroName) =>
    heroName.replace(/\s+/g, "_").replace("&", "and").toLowerCase();
  const decodedHeroName = (heroName) =>
    heroName.replace(/\s+/g, "_").replace("&", "and");

  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });

  const sortedHeroes = [...heroes].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <table className="hero-table">
      <thead>
        <tr>
          <th onClick={() => requestSort("name")}>Hero</th>
          <th onClick={() => requestSort("winrate")}>Winrate</th>
          <th onClick={() => requestSort("pickrate")}>Pickrate</th>
          <th onClick={() => requestSort("totalGames")}>Total Games</th>
          <th onClick={() => requestSort("totalWins")}>Total Wins</th>
        </tr>
      </thead>
      <tbody>
        {sortedHeroes.map((hero) => (
          <tr key={hero.id}>
            <td>
              <div className="hero-name-container">
                <Link to={`/heroes/${decodedHeroName(hero.name)}`}>
                  <img
                    src={`/charicon/${decodedHeroNameLowerCase(
                      hero.name
                    )}.webp`}
                    alt={hero.name}
                    className="hero-icon-table"
                  />
                  <span>{hero.name}</span>
                </Link>
              </div>
            </td>

            <td>
              <div className="winrate-bar-container">
                <div
                  className="winrate-bar"
                  style={{
                    width: `${hero.winrate}%`, // Note the percentage sign
                    backgroundColor:
                      hero.winrate >= 50
                        ? "#3ebb8d"
                        : hero.winrate >= 45
                        ? "#f7dc6f"
                        : "#e8432d",
                  }}
                />
                <div className="winrate-text">{hero.winrate}%</div>
              </div>
            </td>

            <td>
              <div className="pickrate-bar-container">
                <div
                  className="pickrate-bar"
                  style={{
                    width: `${hero.pickrate}%`, // Note the percentage sign
                    backgroundColor:
                      hero.pickrate >= 60
                        ? "#3ebb8d"
                        : hero.pickrate >= 40
                        ? "#f7dc6f"
                        : "#e8432d",
                  }}
                />
                <div className="pickrate-text">{hero.pickrate}%</div>
              </div>
            </td>
            <td>{hero.totalGames}</td>
            <td>{hero.totalWins}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default HeroTable;

import React from "react";

const AddItemButton = ({ onClick }) => {
  return (
    <div
      style={{
        width: "100px",
        height: "115px",
        minWidth: "100px",
        minHeight: "115px",
        backgroundColor: "#233147",
        border: "2px solid white",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        overflow: "hidden",
      }}
      onClick={onClick}
    >
      <span style={{ color: "#6b7280", fontSize: "2rem" }}>+</span>
    </div>
  );
};

export default AddItemButton;

const HeroesData = {
  "Patch 1.2": [
    {
      id: 1,
      name: "Seven",
      icon: "/charicon/seven.webp",
      winrate: 58.75,
      pickrate: 93.41,
      totalGames: 741219,
      totalWins: 436982,
    },
    {
      id: 2,
      name: "Warden",
      icon: "/charicon/warden.webp",
      winrate: 53.19,
      pickrate: 49.69,
      totalGames: 394382,
      totalWins: 209817,
    },
    {
      id: 3,
      name: "Haze",
      icon: "/charicon/haze.webp",
      winrate: 53.31,
      pickrate: 92.02,
      totalGames: 729101,
      totalWins: 388039,
    },
    {
      id: 4,
      name: "McGinnis",
      icon: "/charicon/mcginnis.webp",
      winrate: 52.01,
      pickrate: 60.71,
      totalGames: 494392,
      totalWins: 257102,
    },
    {
      id: 5,
      name: "Kelvin",
      icon: "/charicon/kelvin.webp",
      winrate: 51.61,
      pickrate: 44.45,
      totalGames: 342691,
      totalWins: 176982,
    },
    {
      id: 6,
      name: "Lady Geist",
      icon: "/charicon/lady_geist.webp",
      winrate: 51.33,
      pickrate: 48.35,
      totalGames: 379309,
      totalWins: 194793,
    },
    {
      id: 7,
      name: "Mo & Krill",
      icon: "/charicon/mo_and_krill.webp",
      winrate: 51.44,
      pickrate: 34.62,
      totalGames: 261691,
      totalWins: 134921,
    },
    {
      id: 8,
      name: "Abrams",
      icon: "/charicon/abrams.webp",
      winrate: 51.43,
      pickrate: 55.23,
      totalGames: 443011,
      totalWins: 227771,
    },
    {
      id: 9,
      name: "Lash",
      icon: "/charicon/lash.webp",
      winrate: 50.01,
      pickrate: 37.25,
      totalGames: 286281,
      totalWins: 143051,
    },
    {
      id: 10,
      name: "Pocket",
      icon: "/charicon/pocket.webp",
      winrate: 49.61,
      pickrate: 46.85,
      totalGames: 375391,
      totalWins: 186201,
    },
    {
      id: 11,
      name: "Wraith",
      icon: "/charicon/wraith.webp",
      winrate: 49.51,
      pickrate: 82.07,
      totalGames: 652951,
      totalWins: 323159,
    },
    {
      id: 12,
      name: "Bebop",
      icon: "/charicon/bebop.webp",
      winrate: 49.11,
      pickrate: 70.75,
      totalGames: 558531,
      totalWins: 274492,
    },
    {
      id: 13,
      name: "Ivy",
      icon: "/charicon/ivy.webp",
      winrate: 49.46,
      pickrate: 35.39,
      totalGames: 278951,
      totalWins: 137981,
    },
    {
      id: 14,
      name: "Yamato",
      icon: "/charicon/yamato.webp",
      winrate: 49.61,
      pickrate: 47.04,
      totalGames: 367101,
      totalWins: 182131,
    },
    {
      id: 15,
      name: "Viscous",
      icon: "/charicon/viscous.webp",
      winrate: 48.93,
      pickrate: 35.69,
      totalGames: 281881,
      totalWins: 137891,
    },
    {
      id: 16,
      name: "Infernus",
      icon: "/charicon/infernus.webp",
      winrate: 48.43,
      pickrate: 81.26,
      totalGames: 643699,
      totalWins: 311509,
    },
    {
      id: 17,
      name: "Shiv",
      icon: "/charicon/shiv.webp",
      winrate: 47.81,
      pickrate: 44.15,
      totalGames: 349539,
      totalWins: 167261,
    },
    {
      id: 18,
      name: "Dynamo",
      icon: "/charicon/dynamo.webp",
      winrate: 47.18,
      pickrate: 45.3,
      totalGames: 360146,
      totalWins: 170751,
    },
    {
      id: 19,
      name: "Grey Talon",
      icon: "/charicon/grey_talon.webp",
      winrate: 46.44,
      pickrate: 67.08,
      totalGames: 533141,
      totalWins: 248039,
    },
    {
      id: 20,
      name: "Paradox",
      icon: "/charicon/paradox.webp",
      winrate: 44.7,
      pickrate: 47.21,
      totalGames: 373905,
      totalWins: 167419,
    },
    {
      id: 21,
      name: "Vindicta",
      icon: "/charicon/vindicta.webp",
      winrate: 43.99,
      pickrate: 82.19,
      totalGames: 652345,
      totalWins: 286371,
    },
  ],
};
export default HeroesData;
// old data
// const HeroesData = {
//   "Patch 1.2": [
//     {
//       id: 1,
//       name: "Seven",
//       icon: "/charicon/seven.webp",
//       winrate: 58.95,
//       pickrate: 93.26,
//       totalGames: 864937,
//       totalWins: 509379,
//     },
//     {
//       id: 2,
//       name: "Warden",
//       icon: "/charicon/warden.webp",
//       winrate: 53.15,
//       pickrate: 49.51,
//       totalGames: 459188,
//       totalWins: 244595,
//     },
//     {
//       id: 3,
//       name: "Haze",
//       icon: "/charicon/haze.webp",
//       winrate: 53.26,
//       pickrate: 92.07,
//       totalGames: 853978,
//       totalWins: 454076,
//     },
//     {
//       id: 4,
//       name: "McGinnis",
//       icon: "/charicon/mcginnis.webp",
//       winrate: 51.91,
//       pickrate: 60.6,
//       totalGames: 562101,
//       totalWins: 291979,
//     },
//     {
//       id: 5,
//       name: "Kelvin",
//       icon: "/charicon/kelvin.webp",
//       winrate: 51.35,
//       pickrate: 44.36,
//       totalGames: 411415,
//       totalWins: 211033,
//     },
//     {
//       id: 6,
//       name: "Lady Geist",
//       icon: "/charicon/lady_geist.webp",
//       winrate: 51.27,
//       pickrate: 48.31,
//       totalGames: 448051,
//       totalWins: 229743,
//     },
//     {
//       id: 7,
//       name: "Mo & Krill",
//       icon: "/charicon/mo_and_krill.webp",
//       winrate: 51.23,
//       pickrate: 34.55,
//       totalGames: 320481,
//       totalWins: 164206,
//     },
//     {
//       id: 8,
//       name: "Abrams",
//       icon: "/charicon/abrams.webp",
//       winrate: 51.14,
//       pickrate: 55.17,
//       totalGames: 511749,
//       totalWins: 261725,
//     },
//     {
//       id: 9,
//       name: "Lash",
//       icon: "/charicon/lash.webp",
//       winrate: 49.96,
//       pickrate: 37.21,
//       totalGames: 345130,
//       totalWins: 172448,
//     },
//     {
//       id: 10,
//       name: "Pocket",
//       icon: "/charicon/pocket.webp",
//       winrate: 49.57,
//       pickrate: 46.81,
//       totalGames: 434193,
//       totalWins: 215240,
//     },
//     {
//       id: 11,
//       name: "Wraith",
//       icon: "/charicon/wraith.webp",
//       winrate: 49.52,
//       pickrate: 82.09,
//       totalGames: 761358,
//       totalWins: 377030,
//     },
//     {
//       id: 12,
//       name: "Bebop",
//       icon: "/charicon/bebop.webp",
//       winrate: 49.1,
//       pickrate: 70.77,
//       totalGames: 656443,
//       totalWins: 322342,
//     },
//     {
//       id: 13,
//       name: "Ivy",
//       icon: "/charicon/ivy.webp",
//       winrate: 49.08,
//       pickrate: 35.35,
//       totalGames: 327905,
//       totalWins: 160968,
//     },
//     {
//       id: 14,
//       name: "Yamato",
//       icon: "/charicon/yamato.webp",
//       winrate: 48.86,
//       pickrate: 47.0,
//       totalGames: 435902,
//       totalWins: 212983,
//     },
//     {
//       id: 15,
//       name: "Viscous",
//       icon: "/charicon/viscous.webp",
//       winrate: 48.64,
//       pickrate: 35.65,
//       totalGames: 330722,
//       totalWins: 160873,
//     },
//     {
//       id: 16,
//       name: "Infernus",
//       icon: "/charicon/infernus.webp",
//       winrate: 48.42,
//       pickrate: 81.26,
//       totalGames: 753699,
//       totalWins: 364982,
//     },
//     {
//       id: 17,
//       name: "Shiv",
//       icon: "/charicon/shiv.webp",
//       winrate: 47.77,
//       pickrate: 44.15,
//       totalGames: 409539,
//       totalWins: 196116,
//     },
//     {
//       id: 18,
//       name: "Dynamo",
//       icon: "/charicon/dynamo.webp",
//       winrate: 47.14,
//       pickrate: 45.3,
//       totalGames: 420146,
//       totalWins: 197658,
//     },
//     {
//       id: 19,
//       name: "Grey Talon",
//       icon: "/charicon/grey_talon.webp",
//       winrate: 46.39,
//       pickrate: 67.08,
//       totalGames: 622141,
//       totalWins: 287885,
//     },
//     {
//       id: 20,
//       name: "Paradox",
//       icon: "/charicon/paradox.webp",
//       winrate: 44.65,
//       pickrate: 47.21,
//       totalGames: 437905,
//       totalWins: 195215,
//     },
//     {
//       id: 21,
//       name: "Vindicta",
//       icon: "/charicon/vindicta.webp",
//       winrate: 43.95,
//       pickrate: 82.19,
//       totalGames: 762345,
//       totalWins: 334187,
//     },
//   ],
// };
// export default HeroesData;

import React from "react";
import "./ItemSelection.css";
// import items from "./items";
import itemDataWithPaths from "./itemDataWithPaths";
import { useState } from "react";
const getItemData = (path) => {
  const flattenedData = [].concat(...Object.values(itemDataWithPaths));
  const item = flattenedData.find((item) => item.path === path);
  if (!item) {
    console.error(`Item not found: ${path}`);
  }
  return item;
};
const getItemName = (path) => {
  const item = getItemData(path);
  return item?.name || "Unknown Item";
};

const getItemCost = (path) => {
  const item = getItemData(path);
  return item?.cost || 0;
};

const getItemBackgroundColor = (path) => {
  const item = getItemData(path);
  return item?.type === "Spirit"
    ? "#dd9cff"
    : item?.type === "Vitality"
    ? "#87c81d"
    : "#fbab4d";
};

const getItemCostBackgroundColor = (path) => {
  const item = getItemData(path);
  return item?.type === "Spirit"
    ? "#8b55b4"
    : item?.type === "Vitality"
    ? "#659819"
    : "#ca7a03";
};

const ItemSelection = ({ selectedItems, setSelectedItems, gamePhase }) => {
  const [itemType, setItemType] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");

  const flattenedData = [].concat(...Object.values(itemDataWithPaths));

  const filteredItems = flattenedData.filter((item) => {
    if (itemType === "all") {
      return item.name.toLowerCase().includes(searchQuery.toLowerCase());
    } else {
      return (
        item.type.toLowerCase().includes(itemType.toLowerCase()) &&
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  });

  const toggleItemSelection = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item)); // Deselect item
    } else {
      setSelectedItems([...selectedItems, item]); // Select item
    }
  };

  return (
    <div className="item-selection-section">
      <div className="filter-options">
        <div className="tab-navigation">
          <button
            className={itemType === "all" ? "tab-active" : ""}
            onClick={() => setItemType("all")}
          >
            All
          </button>
          <button
            className={itemType === "weapon" ? "tab-active" : ""}
            onClick={() => setItemType("weapon")}
          >
            Weapon
          </button>
          <button
            className={itemType === "vitality" ? "tab-active" : ""}
            onClick={() => setItemType("vitality")}
          >
            Vitality
          </button>
          <button
            className={itemType === "spirit" ? "tab-active" : ""}
            onClick={() => setItemType("spirit")}
          >
            Spirit
          </button>
        </div>
        <input
          type="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search items by name"
        />
      </div>
      <div className="items-grid">
        {filteredItems.map((item) => (
          <>
            {item.path && (
              <div
                key={item.path}
                className={`item-icon-wrapper ${
                  selectedItems.includes(item.path) ? "selected" : ""
                }`}
                style={{
                  backgroundColor: getItemBackgroundColor(item.path),
                }}
                onClick={() => toggleItemSelection(item.path)}
              >
                <div
                  className="item-cost"
                  style={{
                    backgroundColor: getItemCostBackgroundColor(item.path),
                  }}
                >
                  <img
                    src={`/20px-Souls_icon.png`}
                    alt={getItemName(item.path)}
                    className="souls"
                  />
                  {getItemCost(item.path)}
                </div>
                <img
                  src={item.path}
                  alt={getItemName(item.path)}
                  className="item-icon"
                />
                <div className="item-name">
                  {getItemName(item.path) || "Unknown Item"}
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default ItemSelection;
export { getItemCost };

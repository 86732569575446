// src/components/SearchGuides.jsx

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchGuides.css";
import HeroesData from "./HeroesData";
import { IconSearch } from "../icons";
const SearchGuides = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [guides, setGuides] = useState([]);
  const [heroes, setHeroes] = useState(HeroesData["Patch 1.2"]);
  const [userVotes, setUserVotes] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const formatHeroNameIcon = (name) =>
    name.toLowerCase().replace(/\s+/g, "_").replace("&", "and");
  // Create a reference to the suggestions list
  const suggestionsRef = React.createRef();

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await fetch("https://api.dlg.gg/api/guides");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // Sort guides by voteCount in descending order
        data.sort((a, b) => b.voteCount - a.voteCount);
        setGuides(data);

        // Initialize user votes from local storage or an empty object
        const storedVotes = JSON.parse(localStorage.getItem("userVotes")) || {};
        setUserVotes(storedVotes);
        setLoading(false);
      } catch (error) {
        setError("Failed to load guides");
        setLoading(false);
      }
    };

    fetchGuides();
  }, []);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [suggestionsRef]);

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search?q=${query}`);
  };

  const handleQueryChange = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setQuery(searchQuery);

    const filteredGuides = guides.filter((guide) =>
      guide.title.toLowerCase().includes(searchQuery)
    );

    const filteredHeroes = heroes.filter((hero) =>
      hero.name.toLowerCase().includes(searchQuery)
    );

    const combinedSuggestions = [
      ...filteredGuides.map((guide) => ({ type: "guide", ...guide })),
      ...filteredHeroes.map((hero) => ({ type: "hero", ...hero })),
    ];

    setSuggestions(combinedSuggestions);

    // Use React reference instead of querySelector
    if (suggestionsRef.current) {
      if (searchQuery.length > 0) {
        suggestionsRef.current.classList.add("show");
      } else {
        suggestionsRef.current.classList.remove("show");
      }
    }
  };

  const handleSuggestionClick = (suggestion) => {
    if (suggestion.type === "guide") {
      navigate(`/guides/${suggestion.slug}`);
    } else if (suggestion.type === "hero") {
      navigate(`/heroes/${suggestion.name}`);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // ...

  return (
    <div className="search-guides-container">
      <form onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search for guides"
          value={query}
          onChange={handleQueryChange}
          className="search-input"
        />
        {query && (
          <ul
            ref={suggestionsRef}
            className={`suggestions-list ${query.length > 0 ? "show" : ""}`}
          >
            {suggestions.some((suggestion) => suggestion.type === "guide") && (
              <li
                className="suggestion-header"
                style={{
                  cursor: "default",
                  backgroundColor: "inherit",
                  ":hover": {
                    backgroundColor: "inherit",
                    cursor: "default",
                  },
                }}
              >
                <h3 style={{ marginTop: "0px", marginBottom: "0px" }}>
                  Hero Builds
                </h3>
                <hr />
              </li>
            )}
            {suggestions
              .filter((suggestion) => suggestion.type === "guide")
              .slice(0, 5)
              .map((suggestion) => (
                <li
                  key={suggestion.id}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <div className="suggestion-item">
                    <img
                      src={`/charicon/${formatHeroNameIcon(
                        suggestion.hero
                      )}.webp`}
                      alt={suggestion.hero.name}
                      className="hero-icon"
                      style={{ width: "35px", height: "35px" }}
                    />
                    <span>{suggestion.title}</span>
                  </div>
                </li>
              ))}
            {suggestions.some((suggestion) => suggestion.type === "hero") && (
              <li
                className="suggestion-header hero-header"
                style={{
                  cursor: "default",
                  backgroundColor: "inherit",
                  ":hover": {
                    backgroundColor: "inherit",
                    cursor: "default",
                  },
                }}
              >
                <h3 style={{ marginTop: "0px", marginBottom: "0px" }}>
                  Heroes
                </h3>
                <hr />
              </li>
            )}
            {suggestions
              .filter((suggestion) => suggestion.type === "hero")
              .slice(0, 5)
              .map((suggestion) => (
                <li
                  key={suggestion.id}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <div className="suggestion-item">
                    <img
                      src={suggestion.icon}
                      alt={suggestion.name}
                      className="hero-icon"
                      style={{ width: "30px", height: "30px" }}
                    />
                    <span>{suggestion.name}</span>
                  </div>
                </li>
              ))}
          </ul>
        )}
      </form>
    </div>
  );
};
export default SearchGuides;
